import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { apiConstant } from '../constant';
import useConfirm from '../../../../components/common/useConfirm';
import lodash from 'lodash';
import { isPlainObject } from './utils';
import { useQuery } from '../../../../hooks/UseQuery';
import useDebounce from '../../../../hooks/useDebounce';

const useGetData = (id: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({ list: [] });
  const [error, setError] = useState(null);
  const [render, setRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSync, setIsSync] = useState(false);
  const [Sync, confirmSync] = useConfirm(
    'Row',
    'Are you sure you want to add row?'
  );
  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const page = useQuery('page') || 1;
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const addRow = async () => {
    // const ans = await confirmSync();
    // if (!ans) return;
    let values = { ...moduleData };
    const state = { p: false, r: false };
    values.list.push({
      april: { ...lodash.cloneDeep(state) },
      may: { ...lodash.cloneDeep(state) },
      june: { ...lodash.cloneDeep(state) },
      july: { ...lodash.cloneDeep(state) },
      august: { ...lodash.cloneDeep(state) },
      september: { ...lodash.cloneDeep(state) },
      october: { ...lodash.cloneDeep(state) },
      november: { ...lodash.cloneDeep(state) },
      december: { ...lodash.cloneDeep(state) },
      january: { ...lodash.cloneDeep(state) },
      february: { ...lodash.cloneDeep(state) },
      march: { ...lodash.cloneDeep(state) },
    });
    setModuleData(values);
  };

  const handelSync = async () => {
    const ans = await confirmSync();
    if (!ans) return;
    setIsLoading(true);
    axios
      .get(`/api/${apiConstant.sync}/${id}`)
      .then((res) => {
        window.alert('Sync Succesfully');
        setIsSync((prev) => !prev);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const updateModuleData = useCallback(
    (rowIndex: number, key: string, newValues: any) => {
      setModuleData((prev: any) => {
        // Ensure 'prev' is an object, and 'list' is an array
        const updatedModuleData = { ...prev }; // Clone the previous state object
        const updatedList = [...updatedModuleData.list]; // Clone the 'list' array
  
        // Get the specific row that needs updating
        const updatedRow = { ...updatedList[rowIndex] };
  
        if (newValues === null || Array.isArray(newValues)) {
          updatedRow[key] = newValues;
        } else if (typeof newValues === 'object') {
          updatedRow[key] =
            isPlainObject(updatedRow[key]) && isPlainObject(newValues)
              ? { ...updatedRow[key], ...newValues }
              : newValues;
        } else {
          updatedRow[key] = newValues;
        }
  
        // Update the specific row in the cloned list
        updatedList[rowIndex] = updatedRow;
  
        // Set the updated list back into the moduleData object
        updatedModuleData.list = updatedList;
  
        return updatedModuleData; // Return the updated state object
      });
    },
    []
  );
  
  useEffect(() => {
    setIsLoading(true);
    if (id) {
      axios
        .get(`/api/${apiConstant.getPlanByid}/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, [isSync]);

  // const fetchData1 = async () => {
  //   // setIsLoading(true);

  //   const res: any = await axios
  //     .get(
  //       `/api/${apiConstant.getPlanByid}/${id}?page=${page}&perPage=${10}`,
  //     )
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   setModuleData(res?.data);
  //   // setIsLoading(false);

  //   // setSettings(res?.data?.settings);
  //   // setSelectionArray(new Array(res?.data?.result?.length).fill(null));

  //   setPerPage(res?.data?.perPage);
  //   setTotalPages(res?.data?.pages);
  // };

  // useDebounce(fetchData1, 1000, [page,render]);
 
  const handleDeleteRows = async (rowIdx: number,idx:any) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    if(!idx){
      data.list.splice(rowIdx, 1);
      setModuleData(data);

    }
    const res: any = await axios
    .put(
      `/api/productAuditPlanRoute/maintenancePlanDeleteBulkById/${id}/${idx}`
    )
    .catch((err) => {
      console.log(err);
    });
  if(res?.data?.success==="true"){
    alert("row deleted successfully");
    setRender((prev:any)=>!prev);
  }
  };

  return {
    DialogDeleteRow,
    handleDeleteRows,
    moduleData,
    isLoading,
    error,
    addRow,
    Sync,
    handelSync,
    updateModuleData,
    totalPages,
    perPage,
    page,
    setRender,
  };
};

export default useGetData;
