import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import ObservationInput from './ObservationInput';
import CustomSelect from '../../../../components/common/SelectField';
import { Checkbox, IconButton, MenuItem, Tooltip } from '@mui/material';
import { handleChangeColorOnRemarks } from '../../helpers/handleChangeColorOnRemarks';
import DeleteIcon from '@mui/icons-material/Delete';

interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
}
const TBody = ({ moduleData, tabValue, isView }: IProps) => {
  const [render, setRender] = useState(false);
  console.log(moduleData);
  return (
    <tbody>
      {moduleData.pdirs[tabValue] &&
        moduleData.pdirs[tabValue].pdir_list &&
        moduleData.pdirs[tabValue].pdir_list.map((spec: any, index: number) => (
          <tr key={index + 'specs'}>
            <td>{index + 1}</td>
            <td>{spec.parameter}</td>
            <td>{spec.specification}</td>
            <td>{spec.methods}</td>
            {Array.from({ length: 5 }, (_, obsIndex) => (
              <td key={obsIndex + 'obs'} style={{ textTransform: 'uppercase' }}>
                <ObservationInput
                  {...{ spec, setRender, isView, obsIndex }}
                  obsNumber={0}
                  checkType={spec.appearance}
                  prevType={spec.prev_appearance}
                />
              </td>
            ))}
            <td style={{ textTransform: 'uppercase' }}>
              {spec.appearance === 'check3' ? (
                isView ? (
                  spec.result
                ) : (
                  <CustomSelect
                    name="dropdown"
                    value={spec.result || ''}
                    onChange={(e) => {
                      spec.result = e.target.value;
                      setRender((prev) => !prev);
                    }}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <MenuItem value="">NONE</MenuItem>
                    <MenuItem value="ok">OK</MenuItem>
                    <MenuItem value="ng">NG</MenuItem>
                  </CustomSelect>
                )
              ) : (
                spec.result
              )}
            </td>
            <th
              style={{ textTransform: 'uppercase', width: '13%' }}
              colSpan={isView ? 2 : 1}
            >
              {isView &&
                (spec.remarks === 'other' ? spec.remarks_other : spec.remarks)}
              {!isView &&
                (spec.remarks === 'other' ? (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                      }}
                    >
                      <TableInput
                        isView={isView}
                        name="cycle_time"
                        type="text"
                        defaultValue={spec.remarks_other}
                        onChange={(e) => {
                          spec.remarks_other = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                      <Tooltip title="Delete">
                        <IconButton
                          aria-label="delete"
                          onClick={(e) => {
                            spec.remarks_other = '';
                            spec.remarks = '';
                            setRender((prev) => !prev);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </>
                ) : (
                  <CustomSelect
                    name="dropdown"
                    value={spec.remarks || ''}
                    onChange={(e) => {
                      spec.remarks = e.target.value;
                      handleChangeColorOnRemarks(spec, spec.remarks);
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="none">NONE</MenuItem>
                    <MenuItem value="ok">OK</MenuItem>
                    <MenuItem value="under deviation">UNDER DEVIATION</MenuItem>
                    <MenuItem value="tbc">TBC</MenuItem>
                    <MenuItem value="operation not done">
                      OPERATION NOT DONE
                    </MenuItem>
                    <MenuItem value="other">OTHER</MenuItem>
                  </CustomSelect>
                ))}
            </th>
            {!isView && (
              <td style={{ minWidth: '40px' }}>
                <Checkbox
                  name="changeCheckbox"
                  checked={spec.appearance === 'check3'}
                  onChange={() => {
                    if (spec.prev_appearance) {
                      spec.appearance = spec.prev_appearance;
                      spec.prev_appearance = null;
                    } else {
                      spec.prev_appearance = spec.appearance;
                      spec.appearance = 'check3';
                    }
                    setRender((prev) => !prev);
                  }}
                  style={{ color: '#064a29' }}
                />
              </td>
            )}
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
