import { AiOutlineCloudUpload, AiOutlinePartition } from 'react-icons/ai';
import { AiOutlineFundView } from 'react-icons/ai';
import { BsFillPeopleFill } from 'react-icons/bs';
import { VscServerProcess } from 'react-icons/vsc';
import { FcInspection } from 'react-icons/fc';
import {
  MdApproval,
  MdDriveFolderUpload,
  MdOutlineCompress,
} from 'react-icons/md';
import { TbReport } from 'react-icons/tb';
import { ImStatsDots } from 'react-icons/im';
import { GrTest } from 'react-icons/gr';

const tiles = [
  {
    sNo: 1,
    link: '/common/company_roles_responsibilities_listOfProcedures?documentType=clause4ListOfProcedure',
    isLink: true,
    icon: <MdDriveFolderUpload size="5rem" color="#003566" />,
    p: 'LIST OF',
    span: 'PROCEDURE',
    isIcon: true,
  },
  // {
  //   sNo: 1,
  //   link: '/common/clauseUploadList?documentType=masterProcedureClause4&heading=MASTER PROCEDURE',
  //   isLink: true,
  //   clauseNo: 4,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'MASTER',
  //   span: 'PROCEDURE',
  //   isIcon: true,
  // },
  // {
  //   sNo: 1,
  //   link: '/common/clauseUploadList?documentType=coherencePlanClause4&heading=LIST OF COHERENCE PLAN',
  //   isLink: true,
  //   clauseNo: 4,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'LIST OF COHERENCE',
  //   span: 'PLAN',
  //   isIcon: true,
  // },
  // {
  //   sNo: 1,
  //   link: '/common/clauseUploadList?documentType=coherenceCheckingClause4&heading=LIST OF COHERENCE CHECKING',
  //   isLink: true,
  //   clauseNo: 4,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'LIST OF COHERENCE',
  //   span: 'CHECKING',
  //   isIcon: true,
  // },
  {
    sNo: 1,
    link: '/admin/part_list?filter=part_number',
    isLink: true,
    clauseNo: 4,
    icon: <AiOutlinePartition size="5rem" color="#a12222" />,
    p: 'ADD',
    span: 'PART',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/admin/ppap_documents?filter=part_number',
    isLink: true,
    clauseNo: 4,
    p: 'VIEW',
    span: 'PPAP DOCUMENTS',
    icon: <AiOutlineFundView size="5rem" color="#343a40" />,
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/admin/customer_list?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    p: 'MASTER LIST OF',
    span: 'CUSTOMER',
    icon: <BsFillPeopleFill size="5rem" color="#135089" />,
    isIcon: true,
  },
  // {
  //   sNo: 1,
  //   link: '/admin/pirList',
  //   isLink: true,
  //   icon: <VscServerProcess size="5rem" color="#adb5bd" />,
  //   p: 'LINE',
  //   span: 'INSPECTION OLD',
  //   isIcon: true,
  // },
  // {
  //   sNo: 1,
  //   link: '/admin/lineInspectionList',
  //   isLink: true,
  //   icon: <VscServerProcess size="5rem" color="#a12222" />,
  //   p: 'LINE',
  //   span: 'INSPECTION',
  //   isIcon: true,
  // },
  {
    sNo: 2,
    link: '/common/spcPlanTiles',
    isLink: true,
    icon: <ImStatsDots size="5rem" color="#212529" />,
    p: 'SPC',
    span: 'PLAN',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/documentStandard',
    isLink: true,
    icon: <MdDriveFolderUpload size="5rem" color="#003566" />,
    p: 'DOCUMENT',
    span: 'STANDARD',
    isIcon: true,
  },
  // {
  //   sNo: 1,
  //   link: '/admin/setupList',
  //   isLink: true,
  //   icon: <MdApproval size="5rem" color="#a12222" />,
  //   p: 'FPA/',
  //   span: 'LPA',
  //   isIcon: true,
  // },
  // {
  //   sNo: 1,
  //   link: '/admin/incomingReportList',
  //   isLink: true,
  //   icon: <FcInspection size="5rem" color="#a12222" />,
  //   p: 'INCOMING INSPECTION',
  //   span: 'STANDARD',
  //   isIcon: true,
  // },
  // {
  //   sNo: 1,
  //   link: '/admin/materialIncomingList',
  //   isLink: true,
  //   icon: <FcInspection size="5rem" color="#a12222" />,
  //   p: 'INCOMING MATERIAL',
  //   span: 'INSPECTION',
  //   isIcon: true,
  // },
];

export default tiles;
