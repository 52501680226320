import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import { useQuery } from '../../../../hooks/UseQuery';
import useConfirm from '../../../../components/common/useConfirm';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../components/ui/ChildHeader';
import TController from './components/create/TController';

const View = () => {
  const [moduleData, setModuleData] = useState<any>({
    processes: [],
    pm_date: new Date(),
  });

  const navigate = useNavigate();
  const { id } = useParams();
  console.log("useparams is ",useParams());
  let isView = useQuery('isView') === 'true' ? true : false;
  const toolId = useQuery('toolId');
  const list_id = useQuery('list_id');
  const idx = useQuery('idx');

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );

  useEffect(() => {
    if (idx && idx!=null && idx!="null" && idx!=undefined && idx!="undefined") {
      isView=true; 
      axios
        .get(`/api/toolCheckSheetRoute/${idx}`)
        .then((res) => {
          setModuleData(res?.data);
        })
        .catch((err) => console.log(err));
    }
    else if(id && id!=null && id!="null" && id!=undefined && id!="undefined"){
      axios
      .get(`/api/toolCheckSheetRoute/${id}`)
      .then((res) => {
        setModuleData(res?.data);
      })
      .catch((err) => console.log(err));
    } 
    else {
      axios
        .get(`/api/toolCheckSheetRoute/getFormat/${toolId}`)
        .then((res) => {
          setModuleData((prev: any) => ({ ...prev, ...res?.data }));
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (idx && idx!=null && idx!="null" && idx!=undefined && idx!="undefined") {
      await axios
        .put(`/api/toolCheckSheetRoute/${idx}`, moduleData)
        .then((res) => {
          if (res?.data) {
            alert('Updated Successfully');
          }
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post(
          `/api/toolCheckSheetRoute?toolId=${toolId}&list_id=${list_id}`,
          moduleData
        )
        .then((res) => {
          if (res?.data) {
            alert('created Successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <Box>
        <ChildHeader text="TOOL PREV. MAINT. CHECK SHEET">
          {!isView && moduleData?.processes?.length !== 0 && (
            <SubmitButton label="Submit" onClick={() => handleSumbit()} />
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
        }}
      />

      <DialogSubmit />
    </>
  );
};

export default View;
