import moment from 'moment';
import { useCallback, useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import CustomSelect from '../../../../components/common/SelectField';
import {
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { CustomIconButton } from '../../../../components/common/button/CustomIconButton';
import { MdChecklist } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useConfirm from '../../../../components/common/useConfirm';
import { useParams } from 'react-router-dom';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const monthMap = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const navigate = useNavigate();
  const { id } = useParams();

  const [ConfirmationDialog, confirm] = useConfirm(
    'Update 4M Summary',
    "Do you want to update corresponding 4m summary and update this tool's history ?"
  );

  const navigateCheckList = useCallback(
    (item: any) => {
      navigate(
        `/common/tool/status/checklist/create?idx=${item?.checklist_id}&toolId=${moduleData._id}&list_id=${item?._id}&tool_name=${moduleData?.tool_name}&tool_no=${moduleData?.tool_no}`
      );
    },
    [moduleData]
  );

  const onSubmitWOConfirmation = async () => {
    if (
      !moduleData?.changed_frequncy_prev &&
      moduleData?.changed_frequncy_prev !== 0 &&
      moduleData?.changed_frequncy >= 0
    ) {
      const ans = window.confirm(
        // Changed to confirm to better handle user response
        'Please verify the Tool PM Frequency. It can only be set once and cannot be changed later.'
      );
      if (!ans) return false;
      moduleData.changed_frequncy_prev = moduleData?.changed_frequncy;
    }
    if (
      !moduleData?.initial_cumulative_prev &&
      moduleData?.initial_cumulative_prev !== 0 &&
      moduleData?.initial_cumulative >= 0
    ) {
      const ans = window.confirm(
        'Please verify the Starting Cumulative Frequency.It can only be set once and cannot be changed later.'
      );
      if (!ans) return false;
      moduleData.initial_cumulative_prev = moduleData?.initial_cumulative;
    }
    await axios
      .put(`/api/masterListOfTools/${id}`, moduleData)
      .then((res) => {
        if (res.data) {
          alert('Updated Successfully');
        }
      })
      .catch((err) => console.log(err));
    return true;
  };

  const update4mForUpdatedStatus = async (item: any) => {
    try {
      if (item.tool_unloading_date) {
        //console.log('here 1');
        const date = new Date(item.tool_unloading_date);
        const month = date.getMonth();
        const year = date.getFullYear();
        const monthWord = monthMap[month];
        //console.log('here 2');
        //console.log(`Month-Year: ${monthWord}-${year}`);
        const [fourMSummaryResponse, machineDataResponse] = await Promise.all([
          axios.get(`/api/forMSummaryRoute/${monthWord}-${year}`),
          axios.get('/api/machineRoutes/machine/ForAutocomplete'),
        ]);
        //console.log('here 3');
        const fourMSummaryData = fourMSummaryResponse.data;
        const machineData = machineDataResponse.data;
        //console.log('here 4');
        //console.log('fourMSummaryData: ', fourMSummaryData);
        //console.log('machineData: ', machineData);
        const machine = machineData.find(
          (m: any) => m.machine_no === item.machine_no
        );
        //console.log('here 5');
        //console.log('machine found : ', machine);
        if (!machine) {
          throw new Error('Machine not found');
        }
        const newProcess = {
          date: item.tool_unloading_date,
          part_id: moduleData.part_id._id,
          operation_name: moduleData.tool_name,
          machine_id: machine._id,
          // Add other default values as per the model
        };
        //console.log('here 6');
        //console.log('new process', newProcess);
        fourMSummaryData.processes.push(newProcess);
        //console.log('updated 4 m :', fourMSummaryData);
        await axios.put(
          `/api/forMSummaryRoute/${fourMSummaryData._id}`,
          fourMSummaryData
        );
        setRender((prev) => !prev);
      }
    } catch (error) {
      console.error('Error updating 4m summary:', error);
      // Here you might want to show an error message to the user
    }
  };

  const handleStatusChange = async (e: any, item: any) => {
    const newStatus = e.target.value;
    item.status = newStatus;
    setRender((prev) => !prev);
    if (newStatus === 'GREEN (G)') {
      setCurrentItem(item);
      const shouldUpdate = await confirm();
      if (shouldUpdate) {
        let done = await onSubmitWOConfirmation();
        if (done) {
          await update4mForUpdatedStatus(item);
        }
      }
    }
  };

  const handleConfirm = async () => {
    if (currentItem) {
      await update4mForUpdatedStatus(currentItem);
      currentItem.status = 'GREEN (G)';
      setRender((prev) => !prev);
    }
    setOpenDialog(false);
  };

  return (
    <>

    <tbody>
      {moduleData.list &&
        moduleData.list.slice().reverse().map((item: any, index: number) => (
        // moduleData.list.slice().map((item: any, index: number) => (
          <tr key={`${index}_row`}>
            <td style={{ minWidth: '120px', width: '120px' }}>
              {formatDate(item.date)}
            </td>
           

              <td style={{ minWidth: '160px', width: '160px' }}>
                {isView ? (
                  formatDate(item?.tool_unloading_date)
                ) : (
                  <DatePickerMui
                    value={
                      item?.tool_unloading_date
                        ? moment(item.tool_unloading_date)
                        : null
                    }
                    onChange={(date) => {
                      item.tool_unloading_date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '160px', width: '160px' }}>
                <TableInput
                  isView={true}
                  name="machine_no"
                  type="text"
                  value={item.machine_no}
                />
              </td>
              <td style={{ minWidth: '160px', width: '160px' }}>
                <TableInput
                  isView={true}
                  name="actual_prod"
                  type="number"
                  value={item.actual_prod}
                  // onChange={(e) => {
                  //   item.actual_prod = e.target.value;
                  //   calculateCumulative();
                  //   setRender((prev) => !prev);
                  // }}
                />
              </td>
              <td style={{ minWidth: '160px', width: '160px' }}>
                <TableInput
                  isView={true}
                  name="comulative"
                  type="text"
                  value={item.comulative}
                />
              </td>
              <td
                style={{
                  ...(item.status === 'RED (R)' && { background: '#e03131' }),
                  minWidth: '160px',
                  width: '160px',
                }}
              >
                {/* {item.status} */}

                {/* if the client want to manually set the status then go for it and in calculateCumulative in createToolHistory file remove item.status  */}
                {isView ? (
                  item.status
                ) : (
                  <CustomSelect
                    name="status"
                    value={item.status}
                    sx={{
                      background: `
                      ${
                        (item.status === 'GREEN (G)' && '#40c057') ||
                        (item.status === 'YELLOW (Y)' && '#ffe066') ||
                        (item.status === 'RED (R)' && '#e03131')
                      }
                    `,
                    }}
                    onChange={(e) => {
                      handleStatusChange(e, item);
                    }}
                  >
                    <MenuItem value="">NONE</MenuItem>
                    <MenuItem value="GREEN (G)">GREEN (G)</MenuItem>
                    <MenuItem value="YELLOW (Y)">YELLOW (Y)</MenuItem>
                    <MenuItem value="RED (R)">RED (R)</MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td>
                {item?.status &&
                  (item.status === 'RED (R)' ||
                    item.status === 'GREEN (G)') && (
                    <CustomIconButton
                      sx={{ p: 0 }}
                      tooltipTitle={'CREATE CHECKLIST'}
                      onClick={() => {
                        navigateCheckList(item);
                      }}
                    >
                      <MdChecklist style={{ color: '#343a40' }} />
                    </CustomIconButton>
                  )}
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="reason"
                  type="text"
                  value={item.reason}
                  onChange={(e) => {
                    item.reason = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="action_taken"
                  type="text"
                  value={item.action_taken}
                  onChange={(e) => {
                    item.action_taken = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="updated_by"
                  type="text"
                  value={item.updated_by}
                  onChange={(e) => {
                    item.updated_by = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={item.remarks}
                  onChange={(e) => {
                    item.remarks = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
            </tr>
          ))}
      </tbody>
      <ConfirmationDialog />
    </>
  );
};

export default TBody;
