import { CustomIconButton } from "../../../../components/common/button/CustomIconButton";
import VisibilityIcon from '@mui/icons-material/Visibility';

export const ViewIconButton = ({
    onClick,
    tooltipTitle,
    sx,
  }: {
    onClick?: () => void;
    tooltipTitle: string;
    sx?: any;
  }) => {
    return (
      <CustomIconButton
        tooltipTitle={tooltipTitle}
        color="blueButton"
        sx={{ m: 1, ...sx }}
        disabled={false}
        size="small"
        onClick={onClick}
      >
        <VisibilityIcon sx={{ color: 'white' }} />
      </CustomIconButton>
    );
  };