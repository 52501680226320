import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { apiConstant } from './constant';
import useConfirm from '../../../../components/common/useConfirm';
import { useQuery } from '../../../../hooks/UseQuery';
import { useNavigate } from 'react-router-dom';

const useGetData = (id: string | undefined | null) => {
  const [moduleData, setModuleData] = useState<any>({
    list: [],
  });
  const navigate = useNavigate();
  const month = useQuery('month');
  const documentId = useQuery('documentId');
  const rowId = useQuery('rowId');
  const idx = useQuery('id'); 
  const row_id = useQuery('row_id'); 
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [triggerRender, setTriggerRender] = useState(false);
  const [masterCheckListData, setMasterCheckListData] = useState([{}]);
  const [checkSheetType, setCheckSheetType] = useState<any>(null);
  const [Sync, confirmSync] = useConfirm(
    'SYNC',
    'Are you sure you want to sync? Save any changes before sync!'
  );

  const handleDelete = async () => {
    axios
      .delete(
        `/api/${apiConstant.delete}/${id}?row_id=${row_id}&id=${idx}&rowId=${rowId}&documentId=${documentId}&month=${month}`
      )
      .then((res) => {
        alert('Succesfully deleted');
        navigate(-1);
      })
      .catch((err) => {
        setError(err);
        alert('cant  deleted');
      });
  };

  const updateModuleData = useCallback(
    (
      updateType: 'key-pair' | 'row',
      rowIndex: number | null,
      key: string,
      newValues: any
    ) => {
      setModuleData((prev: any) => {
        const updatedModuleData: any = { ...prev };
        if (updateType === 'key-pair') {
          updatedModuleData[key] = newValues[key];
        } else if (updateType === 'row') {
          //specific row that needs updating
          if (rowIndex === null) return;
          const updatedRow = { ...updatedModuleData.list[rowIndex] };
          updatedRow[key] = newValues[key];
          updatedModuleData.list[rowIndex] = updatedRow;
        }
        return updatedModuleData;
      });
    },
    []
  );

  const addRow = useCallback(() => {
    setModuleData((prev: any) => {
      return {
        ...prev,
        list: [...prev.list, { _tempId: `temp-id-${new Date().getTime()}` }],
      };
    });
  }, []);

  useEffect(() => {
    axios
      .get(`/api/${apiConstant.masterCheckListAutoComplete}`)
      .then((res) => {
        setMasterCheckListData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      axios
        .get(`/api/${apiConstant.getById}/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else if (checkSheetType) {
      setIsLoading(true);
      axios
        .get(
          `/api/${apiConstant.getMasterCheckListById}/${checkSheetType?._id}`
        )
        .then((res) => {
          const data = res.data;
          delete data._id;
          delete data.__v;
          delete data.updatedAt;
          delete data.createdAt;
          setModuleData(data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      setModuleData({
        list: [],
      });
    }
  }, [triggerRender, checkSheetType]);

  return {
    moduleData,
    isLoading,
    error,
    Sync,
    updateModuleData,
    handleDelete,
    masterCheckListData,
    addRow,
    setTriggerRender,
    checkSheetType,
    setCheckSheetType,
  };
};

export default useGetData;
