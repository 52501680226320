// import React, { useState, useEffect } from 'react';
// import { 
//   Modal,
//   Box,
//   Table, 
//   TableBody, 
//   TableCell, 
//   TableContainer, 
//   TableHead, 
//   TableRow, 
//   Paper, 
//   Select, 
//   MenuItem, 
//   Input,
//   Button,
//   CircularProgress
// } from '@mui/material';
// import { IProcess, IDowntimeReason } from '../../helpers/interface';
// import moment from 'moment';
// import axios from 'axios';
// import { useQuery } from '../../../../../hooks/UseQuery';

// interface DowntimeReasonOption {
//     _id: string;
//     name: string;
//     duration: number;
// }

// interface DowntimeReasonModalProps {
//   process: IProcess;
//   isOpen: boolean;
//   isFreeze: boolean;
//   onClose: () => void;
//   onSave: (updatedProcess: IProcess) => void;
//   documentType:any;
// }

// const downtimeReasons = [
//   'Machine Breakdown',
//   'Material Shortage',
//   'Tool Change',
//   'Setup Time',
//   'Quality Issue',
//   'Other'
// ];

// const DowntimeReasonModal: React.FC<DowntimeReasonModalProps> = ({ process, isOpen, onClose, onSave, documentType,isFreeze }) => {
//   const [reasons, setReasons] = useState<IDowntimeReason[]>(process.downtime_reasons || []);
//   const [downtimeReasons, setDowntimeReasons] = useState<DowntimeReasonOption[]>([]);
//   let viewMode = useQuery('isView') || 'false';
//   console.log('view Mode:', viewMode)
//   //const documentType = useQuery('documentType');

//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     setReasons(process.downtime_reasons || []);
//   }, [process]);

//   // useEffect(() => {
//   //   const fetchDowntimeReasons = async () => {
//   //     try {
//   //       const response = await axios.get('/api/downtimeReasonRoute/autocomplete');
//   //       setDowntimeReasons(response.data);
//   //       setLoading(false);
//   //     } catch (error) {
//   //       console.error('Error fetching downtime reasons:', error);
//   //       setLoading(false);
//   //     }
//   //   };

//   //   fetchDowntimeReasons();
//   // }, []);

//   useEffect(() => {
//     const fetchDowntimeReasons = async () => {
//       try {
//         const response = await axios.get('/api/downtimeReasonRoute/autocomplete');
//         const fetchedReasons: DowntimeReasonOption[] = response.data;
  
//         const lunchBreak = fetchedReasons.find((r) => r.name === 'Lunch Break');
//         const lunchBreakWithFixedDuration = {
//           ...lunchBreak,
//           duration: 30,
//         };
//         const teaBreak = fetchedReasons.find((r) => r.name === 'Tea Break');
//         const replicatedTeaBreaks = Array.from({ length: 3 }).map(() => ({
//           ...teaBreak,
//           duration: 10, 
//         }));
  
//         const defaultBreaks = [lunchBreakWithFixedDuration, ...replicatedTeaBreaks];
//         setDowntimeReasons(fetchedReasons);
//         setReasons((prevReasons:any) =>
//           prevReasons?.length
//             ? prevReasons
//             : defaultBreaks.map((breakReason:any) => ({
//                 reason: breakReason?.name,
//                 duration: breakReason?.duration,
//                 _id: breakReason?._id,
//               }))
//         );
  
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching downtime reasons:', error);
//         setLoading(false);
//       }
//     };
  
//     fetchDowntimeReasons();
//   }, [process]);
  
  
//   const addRow = () => {
//     setReasons([...reasons, { reason: '', duration: 0 }]);
//   };

//   const updateReason = (index: number, field: keyof IDowntimeReason, value: string | number) => {
//     const updatedReasons = reasons.map((reason, i) => 
//       i === index ? { ...reason, [field]: value } : reason
//     );
//     setReasons(updatedReasons);
//   };

//   const calculateTotalDowntime = () => {
//     return reasons.reduce((sum, reason) => sum + reason.duration, 0);
//   };

//   const calculatePlanQty = (updatedProcess: IProcess) => {
//     const cycleTime = updatedProcess.cycle_time || null;
//     if(cycleTime === null || cycleTime === undefined || cycleTime === 0){
//       return null;
//     }

//     if(!updatedProcess.time_start || !updatedProcess.time_stop){
//       return null;
//     }
    
//     if (cycleTime && updatedProcess.time_start && updatedProcess.time_stop) {
//       const startTime = moment(updatedProcess.time_start);
//       const stopTime = moment(updatedProcess.time_stop);
//       let durationInMinutes = stopTime.diff(startTime, 'minutes');
//       if (durationInMinutes < 0) {
//         durationInMinutes += 1440;
//       }
//       durationInMinutes -= updatedProcess.total_downtime || 0;
//       const durationInHours = durationInMinutes / 60;
//       return Math.floor(durationInHours * cycleTime);
//     }
//     return null;
//   };

//   const handleSave = () => {
//     const totalDowntime = calculateTotalDowntime();
//     const updatedProcess: IProcess = {
//       ...process,
//       downtime_reasons: reasons,
//       total_downtime: totalDowntime,
//     };
//     updatedProcess.plan_qty = (documentType === 'pressShop3') ? (updatedProcess.plan_qty) : (calculatePlanQty(updatedProcess));
//     onSave(updatedProcess);
//     onClose();
//   };

//   if (loading) {
//     return (
//       <Modal open={isOpen} onClose={onClose}>
//         <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
//           <CircularProgress />
//         </Box>
//       </Modal>
//     );
//   }

//   return (
//     <Modal
//       open={isOpen}
//       onClose={onClose}
//       aria-labelledby="downtime-modal-title"
//       aria-describedby="downtime-modal-description"
//     >
//       <Box sx={{
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         width: 800,
//         bgcolor: 'background.paper',
//         boxShadow: 24,
//         p: 4,
//       }}>
//         <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
//             <Box>
//             <h2 id="downtime-modal-title">DOWNTIME</h2>
//             </Box>
//             <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
//               {(viewMode == 'false')&&(
//                 (!isFreeze &&(

//                 <Button variant="contained" onClick={addRow} sx={{ mr: 1 }}>
//                     Add Row
//                 </Button>
//                 ))
//               )}
//               {(viewMode == 'false')&&(
//                 <Button variant="contained" onClick={handleSave}>
//                     Save
//                 </Button>
//               )}
//             </Box>
//         </Box>
//         <TableContainer component={Paper}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell width="10%">S.No.</TableCell>
//                 <TableCell width="70%">Reason</TableCell>
//                 <TableCell width="20%">Duration (minutes)</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {reasons.map((reason, index) => (
//                 <TableRow key={index}>
//                   <TableCell>{index + 1}</TableCell>
//                   <TableCell>
//                     <Select
//                       fullWidth
//                       value={reason.reason}
//                       disabled={isFreeze}
//                       onChange={(e) => updateReason(index, 'reason', e.target.value as string)}
//                     >
//                       {downtimeReasons.map((r) => (
//                         <MenuItem key={r._id} value={r.name}>{r.name}</MenuItem>
//                       ))}
//                     </Select>
//                   </TableCell>
//                   <TableCell>
//                     <Input
//                       type="number"
//                       disabled={isFreeze}
//                       value={reason.duration}
//                       onChange={(e) => updateReason(index, 'duration', Number(e.target.value))}
//                       inputProps={{ style: { width: '60px' } }}
//                     />
//                   </TableCell>
//                 </TableRow>
//               ))}
//               <TableRow>
//                 <TableCell colSpan={2}>Total Downtime</TableCell>
//                 <TableCell>{calculateTotalDowntime()} minutes</TableCell>
//               </TableRow>
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Box>
//     </Modal>
//   );
// };

// export default DowntimeReasonModal;



import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  Input,
  Button,
  CircularProgress,
} from '@mui/material';
import { IProcess, IDowntimeReason } from '../../helpers/interface';
import moment from 'moment';
import axios from 'axios';
import { useQuery } from '../../../../../hooks/UseQuery';

interface DowntimeReasonOption {
  _id: string;
  name: string;
  duration: number;
}

interface DowntimeReasonModalProps {
  process: IProcess;
  isOpen: boolean;
  isFreeze: boolean;
  onClose: () => void;
  onSave: (updatedProcess: IProcess) => void;
  documentType: any;
}

const DowntimeReasonModal: React.FC<DowntimeReasonModalProps> = ({
  process,
  isOpen,
  onClose,
  onSave,
  documentType,
  isFreeze,
}) => {
  const [renderKey, setRenderKey] = useState(0);

  const [reasons, setReasons] = useState<IDowntimeReason[]>([]
    // isFreeze ?  [] : process.scheduled_downtime_reasons || []
    // isFreeze ? process.downtime_reasons || [] : process.scheduled_downtime_reasons || []
  );
  const [downtimeReasons, setDowntimeReasons] = useState<DowntimeReasonOption[]>([]);
  let viewMode = useQuery('isView') || 'false';
  let initialReasons: { reason: string; duration: number }[] = isFreeze
  ? []
  : process.scheduled_downtime_reasons || [];
  useEffect(() => {
    // Fetch reasons from the process based on isFreeze
    if (isFreeze) {
      setReasons(process.downtime_reasons || []);
    } else {
      setReasons(process.scheduled_downtime_reasons || []);
    }
  }, [process,isOpen,onClose,isFreeze]);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const initializeReasons = () => {
  //     let initialReasons = isFreeze ? process.downtime_reasons || [] : process.scheduled_downtime_reasons || [];
  
  //     if (isFreeze) {
  //       const breakTimes = [
  //         { reason: 'Tea Break', start: '11:00', end: '11:10', duration: 10 },
  //         { reason: 'Lunch Break', start: '13:00', end: '13:30', duration: 30 },
  //         { reason: 'Tea Break', start: '15:30', end: '15:40', duration: 10 },
  //         { reason: 'Tea Break', start: '17:30', end: '17:40', duration: 10 },
  //       ];
  
  //       const startTime = moment(process.time_start, 'HH:mm');
  //       const stopTime = moment(process.time_stop, 'HH:mm');
  
  //       breakTimes.forEach((breakTime) => {
  //         const breakStart = moment(breakTime.start, 'HH:mm');
  //         const breakEnd = moment(breakTime.end, 'HH:mm');
  
  //         if (startTime.isBetween(breakStart, breakEnd, null, '[)') || stopTime.isBetween(breakStart, breakEnd, null, '(]')) {
  //           // If either start_time or stop_time falls within the break time range
  //           initialReasons = [
  //             ...initialReasons,
  //             { reason: breakTime.reason, duration: breakTime.duration },
  //           ];
  //         } else if (stopTime.isAfter(breakEnd)) {
  //           // If the stop_time is after the break time has passed
  //           initialReasons = [
  //             ...initialReasons,
  //             { reason: breakTime.reason, duration: breakTime.duration },
  //           ];
  //         }
  //       });
  //     }
  //   }
  //   initializeReasons();
    
  //   // setReasons(
  //   //   isFreeze ? process.downtime_reasons || [] : process.scheduled_downtime_reasons || []
  //   // );
  // }, [isFreeze, process]);
  // useEffect(() => {
  //   const initializeReasons = () => {
  //     let initialReasons = isFreeze ? process.downtime_reasons || [] : process.scheduled_downtime_reasons || [];
  
  //     if (isFreeze) {
  //       const breakTimes = [
  //         { reason: 'Tea Break', start: '11:00', end: '11:10', duration: 10 },
  //         { reason: 'Lunch Break', start: '13:00', end: '13:30', duration: 30 },
  //         { reason: 'Tea Break', start: '15:30', end: '15:40', duration: 10 },
  //         { reason: 'Tea Break', start: '17:30', end: '17:40', duration: 10 },
  //       ];
  
  //       const startTime = moment(process.time_start, 'HH:mm');
  //       const stopTime = moment(process.time_stop, 'HH:mm');

  //       const startHours = startTime.hour();
  //       const startMinutes = startTime.minute();
        
  //       // Extract hours and minutes for stopTime
  //       const stopHours = stopTime.hour();
  //       const stopMinutes = stopTime.minute();
        
  //       console.log("Start Time - Hours:", startHours, "Minutes:", startMinutes);
  //       console.log("Stop Time - Hours:", stopHours, "Minutes:", stopMinutes);
  //       console.log("startTime is ", startTime);
  //       console.log("stopTime is ", stopTime);
  
  //       // breakTimes.forEach((breakTime) => {
  //         // const breakStart = moment(breakTime.start, 'HH:mm');
  //         // const breakEnd = moment(breakTime.end, 'HH:mm');
  //         if(startHours>=11 && stopHours>=11){
  //           initialReasons = [
  //             ...initialReasons,
  //             { reason: "Tea Break", duration: 10 },
  //           ]; 
  //         }
  //          if(stopHours>=13){
  //           initialReasons = [
  //             ...initialReasons,
  //             { reason: "Lunch Break", duration: 30 },
  //           ]; 
  //         }
  //          if ((stopHours>=15 && stopHours<=16 && stopMinutes>=30) || (stopHours>=16)) {
  //           initialReasons = [
  //             ...initialReasons,
  //             { reason: "Tea Break", duration: 10 },
  //           ]; 
  //         }
  //         if((stopHours>=17 && stopHours<=18 && stopMinutes>=30) || (stopHours>=18)){
  //         initialReasons = [
  //           ...initialReasons,
  //           { reason: "Tea Break", duration: 10 },
  //         ]; 
  //        } 
  //         //   ){
  //         //     initialReasons = [
  //         //       ...initialReasons,
  //         //       { reason: breakTime.reason, duration: breakTime.duration },
  //         //     ];  
  //         //     console.log("initialReasons is ",initialReasons)
  //         // }
  //         // Check if the start or stop time falls within or is greater than the break time
  //         // if (startTime.isBetween(breakStart, breakEnd, null, '[)') || stopTime.isBetween(breakStart, breakEnd, null, '(]') || stopTime.isAfter(breakEnd)) {
  //         //   initialReasons = [
  //         //     ...initialReasons,
  //         //     { reason: breakTime.reason, duration: breakTime.duration },
  //         //   ];
  //         // }
  //       // }
  //     // );
  //     }
  
  //     console.log("initialReasons",initialReasons )
  //     setReasons(initialReasons);
  //   };
  //   initializeReasons();
  // }, [isFreeze, process,process.time_start,process.time_stop]);
  
  useEffect(() => {
    // setRenderKey((prevKey) => prevKey + 1);
  }, [isOpen, onClose]);
  // useEffect(() => {
  //   const initializeReasons = () => {
  //     // let initialReasons: { reason: string; duration: number }[] = isFreeze
  //     //   ? process.downtime_reasons || []
  //     //   : process.scheduled_downtime_reasons || [];
  
  //     const startTime = moment(process.time_start, 'HH:mm');
  //     const stopTime = moment(process.time_stop, 'HH:mm');

  //     const startHours = startTime.hour();
  //     const stopHours = stopTime.hour();
  //     if (isFreeze) {
  //       // initialReasons=[];
  //       const stopMinutes = stopTime.minute();
  
  //       const additionalReasons: { reason: string; duration: number }[] = [];
  //   console.log("startHours is ",startHours);
  //   console.log("stopHours is ",stopHours);
  //       // Count the number of tea breaks already added
  //       let teaBreakCount = initialReasons.filter((r) => r.reason === 'Tea Break').length;
  
  //       // if(startHours>=11 && stopHours<=18){

  //         // Add Tea Break for 11:00 - 11:10
  //         if (startHours >= 11 && stopHours >= 11 && teaBreakCount < 3) {
  //         additionalReasons.push({ reason: 'Tea Break', duration: 10 });
  //         teaBreakCount++;
  //       }
  
  //       // Add Lunch Break for 13:00 - 13:30
  //       if (stopHours >= 13 && !initialReasons.some((r) => r.reason === 'Lunch Break')) {
  //         additionalReasons.push({ reason: 'Lunch Break', duration: 30 });
  //       }
        
  //       // Add Tea Break for 15:30 - 15:40
  //       if (stopHours > 15 || (stopHours === 15 && stopMinutes >= 30)) {
  //         if (teaBreakCount < 3) {
  //           additionalReasons.push({ reason: 'Tea Break', duration: 10 });
  //           teaBreakCount++;
  //         }
  //       }
        
  //       // Add Tea Break for 17:30 - 17:40
  //       if (stopHours > 17 || (stopHours === 17 && stopMinutes >= 30)) {
  //         if (teaBreakCount < 3) {
  //           additionalReasons.push({ reason: 'Tea Break', duration: 10 });
  //           teaBreakCount++;
  //         }
  //       }
        
  //       // Merge initialReasons with additionalReasons
  //       const combinedReasons = [...initialReasons, ...additionalReasons];
        
  //       console.log('combinedReasons initialReasons:', combinedReasons);
  //       console.log('additionalReasons :', additionalReasons);
  //       // Deduplicate by reason and duration
  //       setReasons(combinedReasons);
  //     // }
  //     }
  
  //   };
  
  //   initializeReasons();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isFreeze,renderKey,isOpen,onClose,process.time_start, process.time_stop,reasons]);
 
  useEffect(() => {
    const initializeReasons = () => {
      const startTime = moment.utc(process.time_start).local();
    const stopTime = moment.utc(process.time_stop).local();

    const startHours = startTime.hour();
    const startMinutes = startTime.minute();
    const stopHours = stopTime.hour();
    const stopMinutes = stopTime.minute();
   console.log("startHours is ",startHours);
   console.log("stopHours is ",stopHours);
      if (isFreeze) {
        const additionalReasons: { reason: string; duration: number }[] = [];
  
        // Check and add Tea Break (11:00 - 11:10)
        if (
          (startHours < 11 || (startHours === 11 && startMinutes <= 10)) &&
          (stopHours > 11 || (stopHours === 11 && stopMinutes >0))
        ) {
          additionalReasons.push({ reason: 'Tea Break', duration: 10 });
        }
  
        // Check and add Lunch Break (13:00 - 13:30)
        if (
          (startHours < 13 || (startHours === 13 && startMinutes <= 30)) &&
          (stopHours > 13 || (stopHours === 13 && stopMinutes > 0))
        ) {
          additionalReasons.push({ reason: 'Lunch Break', duration: 30 });
        }
  
        // Check and add Tea Break (15:30 - 15:40)
        if (
          (startHours < 15 || (startHours === 15 && startMinutes <= 40)) &&
          (stopHours > 15 || (stopHours === 15 && stopMinutes >30))
        ) {
          additionalReasons.push({ reason: 'Tea Break', duration: 10 });
        }
  
        // Check and add Tea Break (17:30 - 17:40)
        if (
          (startHours < 17 || (startHours === 17 && startMinutes <= 40)) &&
          (stopHours > 17 || (stopHours === 17 && stopMinutes > 30))
        ) {
          additionalReasons.push({ reason: 'Tea Break', duration: 10 });
        }
  
        // Deduplicate by reason and duration
        const combinedReasons = [...initialReasons, ...additionalReasons];
  
        // Ensure uniqueness of reasons
        // const uniqueReasons = Array.from(
        //   new Map(combinedReasons.map((r) => [r.reason, r])).values()
        // );
  
        console.log('Combined Reasons:', combinedReasons);
        setReasons(combinedReasons);
      }
    };
  
    initializeReasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reasons,process.time_start, process.time_stop]);
  // }, [isFreeze, process.time_start, process.time_stop]);
  
  useEffect(() => {
    const fetchDowntimeReasons = async () => {
      try {
        const response = await axios.get('/api/downtimeReasonRoute/autocomplete');
        const fetchedReasons: DowntimeReasonOption[] = response.data;

        setDowntimeReasons(fetchedReasons);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching downtime reasons:', error);
        setLoading(false);
      }
    };

    fetchDowntimeReasons();
  }, []);

  const addRow = () => {
    setReasons([...reasons, { reason: '', duration: 0 }]);
  };

  const updateReason = (index: number, field: keyof IDowntimeReason, value: string | number) => {
    const updatedReasons = reasons.map((reason, i) =>
      i === index ? { ...reason, [field]: value } : reason
    );
    setReasons(updatedReasons);
  };

  const calculateTotalDowntime = () => {
    return reasons.reduce((sum, reason) => sum + reason.duration, 0);
  };

  const calculatePlanQty = (updatedProcess: IProcess) => {
    const cycleTime = updatedProcess.cycle_time || null;
    if (cycleTime === null || cycleTime === undefined || cycleTime === 0) {
      return null;
    }

    if (!updatedProcess.time_start || !updatedProcess.time_stop) {
      return null;
    }

    const startTime = moment(updatedProcess.time_start);
    const stopTime = moment(updatedProcess.time_stop);
    let durationInMinutes = stopTime.diff(startTime, 'minutes');
    if (durationInMinutes < 0) {
      durationInMinutes += 1440;
    }
    const totalDowntime = isFreeze
      ? updatedProcess.total_downtime || 0
      : updatedProcess.total_schedule_downtime || 0;
    durationInMinutes -= totalDowntime;
    const durationInHours = durationInMinutes / 60;
    return Math.floor(durationInHours * cycleTime);
  };

  const handleSave = () => {
    const totalDowntime = calculateTotalDowntime();
    const updatedProcess: IProcess = {
      ...process,
      downtime_reasons: isFreeze ? reasons : process.downtime_reasons,
      scheduled_downtime_reasons: !isFreeze ? reasons : process.scheduled_downtime_reasons,
      total_downtime: isFreeze ? totalDowntime : process.total_downtime,
      total_schedule_downtime: !isFreeze ? totalDowntime : process.total_schedule_downtime,
    };
    updatedProcess.plan_qty =
      documentType === 'pressShop3' ? updatedProcess.plan_qty : calculatePlanQty(updatedProcess);
    onSave(updatedProcess);
    onClose();
  };

  if (loading) {
    return (
      <Modal open={isOpen} onClose={onClose}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="downtime-modal-title"
      aria-describedby="downtime-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box>
            <h2 id="downtime-modal-title">DOWNTIME</h2>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            {viewMode == 'false' && !isFreeze && (
              <Button variant="contained" onClick={addRow} sx={{ mr: 1 }}>
                Add Row
              </Button>
            )}
            {viewMode == 'false' && (
              <Button variant="contained" onClick={handleSave}>
                Save
              </Button>
            )}
          </Box>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="10%">S.No.</TableCell>
                <TableCell width="70%">Reason</TableCell>
                <TableCell width="20%">Duration (minutes)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reasons.map((reason, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <Select
                      fullWidth
                      value={reason.reason}
                      onChange={(e) =>
                        updateReason(index, 'reason', e.target.value as string)
                      }
                      disabled={isFreeze}
                    >
                      {downtimeReasons.map((r) => (
                        <MenuItem key={r._id} value={r.name}>
                          {r.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Input
                      type="number"
                      value={reason.duration}
                      onChange={(e) =>
                        updateReason(index, 'duration', Number(e.target.value))
                      }
                      disabled={isFreeze}
                      inputProps={{ style: { width: '60px' } }}
                    />
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2}>Total Downtime</TableCell>
                <TableCell>{calculateTotalDowntime()} minutes</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default DowntimeReasonModal;
