import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  handleDelete: any;
  setRenderforUpdate: any;
  isView: boolean;

  machineData: any;
  uploadImageFile: any;
}

const TBody = ({
  moduleData,
  isView,
  handleDelete,
  uploadImageFile,
  setRenderforUpdate,
  machineData,
}: IProps) => {
  return (
    <tbody>
      {moduleData?.map((item: any, index: number) => (
        <TBodyRow
          {...{
            item,
            index,
            uploadImageFile,
            isView,
            setRenderforUpdate,
            handleDelete,
            machineData,
          }}
        />
      ))}
    </tbody>
  );
};

export default TBody;
