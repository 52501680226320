import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
import TFoot from './TFoot';
import { useState } from 'react';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  setModuleData: any;
  setFilteredSummary: any;
  filteredSummary: any;
}

const TController = ({
  moduleData,
  isView,
  handelDeleteRows,
  setIsOpenPopup,
  setPartIdx,
  setModuleData,
  setFilteredSummary,
  filteredSummary,
}: IProps) => {
console.log("moduleData is ",moduleData);
 
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            setModuleData,
            isView,
            setFilteredSummary,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handelDeleteRows,
            setIsOpenPopup,
            setPartIdx,
            filteredSummary,
          }}
        />
        <TFoot
          {...{
            moduleData,
            isView,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
