// import moment from 'moment';
// import { useState } from 'react';
// import { TableInput } from '../../../../components/common';
// import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';
// import DatePickerMui from '../../../../components/common/DatePickerMui';
// import formatDate from '../../../../components/common/formatDate';
// import { TimePicker } from '@mui/x-date-pickers';
// import CustomSelect from '../../../../components/common/SelectField';
// import { MenuItem } from '@mui/material';
// import TextAreaInput from '../../../../components/common/TextAreaInput';

// interface IProps {
//   moduleData: any;
//   isView: boolean;
//   handelDeleteRows: any;
// }
// const TBody = ({ moduleData, isView, handelDeleteRows }: IProps) => {
//   const [render, setRender] = useState(false);
//   return (
//     <tbody>
//       {moduleData.histories &&
//         moduleData.histories.map((spec: any, index: number) => (
//           <tr>
//             <td style={{ minWidth: '150px' }}>
//               {isView ? (
//                 formatDate(spec.date)
//               ) : (
//                 <DatePickerMui
//                   value={moment(spec.date)}
//                   onChange={(date) => {
//                     spec.date = date;
//                     setRender((prev) => !prev);
//                   }}
//                 />
//               )}
//             </td>
//             <td style={{ minWidth: '150px' }}>
//               {isView ? (
//                 formatDate(spec.time)
//               ) : (
//                 <TimePicker
//                   value={moment(spec.time)}
//                   onChange={(date) => {
//                     spec.time = date;
//                     setRender((prev) => !prev);
//                   }}
//                 />
//               )}
//             </td>
//             <td style={{ minWidth: '150px' }}>
//               {isView ? (
//                 spec.maintenance_bd
//               ) : (
//                 <CustomSelect
//                   label=""
//                   name="maintenance_bd"
//                   value={spec.maintenance_bd}
//                   onChange={(e: any) => {
//                     spec.maintenance_bd = e.target.value;
//                     setRender((prev) => !prev);
//                   }}
//                 >
//                   <MenuItem value="YES">YES</MenuItem>
//                   <MenuItem value="NO">NO</MenuItem>
//                 </CustomSelect>
//               )}
//             </td>
//             <td style={{ minWidth: '150px' }}>
//               {isView ? (
//                 spec.maintenance_prev
//               ) : (
//                 <CustomSelect
//                   label=""
//                   name="maintenance_prev"
//                   value={spec.maintenance_prev}
//                   onChange={(e: any) => {
//                     spec.maintenance_prev = e.target.value;
//                     setRender((prev) => !prev);
//                   }}
//                 >
//                   <MenuItem value="YES">YES</MenuItem>
//                   <MenuItem value="NO">NO</MenuItem>
//                 </CustomSelect>
//               )}
//             </td>
//             <td style={{ minWidth: '300px' }}>
//               <TextAreaInput
//                 isView={isView}
//                 name="reason_for_maintenance"
//                 value={spec.reason_for_maintenance}
//                 onChange={(e) => {
//                   spec.reason_for_maintenance = e.target.value;
//                   setRender((prev) => !prev);
//                 }}
//               />
//             </td>
//             <td style={{ minWidth: '300px' }}>
//               <TextAreaInput
//                 isView={isView}
//                 name="action_taken"
//                 value={spec.action_taken}
//                 onChange={(e) => {
//                   spec.action_taken = e.target.value;
//                   setRender((prev) => !prev);
//                 }}
//               />
//             </td>
//             <td style={{ minWidth: '150px' }}>
//               {isView ? (
//                 formatDate(spec.rectification_date)
//               ) : (
//                 <DatePickerMui
//                   value={moment(spec.rectification_date)}
//                   onChange={(date) => {
//                     spec.rectification_date = date;
//                     setRender((prev) => !prev);
//                   }}
//                 />
//               )}
//             </td>
//             <td style={{ minWidth: '150px' }}>
//               {isView ? (
//                 formatDate(spec.rectification_time)
//               ) : (
//                 <TimePicker
//                   value={moment(spec.rectification_time)}
//                   onChange={(date) => {
//                     spec.rectification_time = date;
//                     setRender((prev) => !prev);
//                   }}
//                 />
//               )}
//             </td>
//             <td style={{ minWidth: '150px' }}>
//               <TableInput
//                 isView={isView}
//                 name="idle_hr_per_day"
//                 type="text"
//                 value={spec.idle_hr_per_day}
//                 onChange={(e) => {
//                   spec.idle_hr_per_day = e.target.value;
//                   setRender((prev) => !prev);
//                 }}
//               />
//             </td>
//             <td style={{ minWidth: '150px' }}>
//               <TableInput
//                 isView={isView}
//                 name="sign"
//                 type="text"
//                 value={spec.sign}
//                 onChange={(e) => {
//                   spec.sign = e.target.value;
//                   setRender((prev) => !prev);
//                 }}
//               />
//             </td>
//             <td style={{ minWidth: '150px' }}>
//               <TableInput
//                 isView={isView}
//                 name="remarks"
//                 type="text"
//                 value={spec.remarks}
//                 onChange={(e) => {
//                   spec.remarks = e.target.value;
//                   setRender((prev) => !prev);
//                 }}
//               />
//             </td>
//             {!isView && (
//               <td style={{ minWidth: '100px', textAlign: 'center' }}>
//                 <DeleteIconButton
//                   tooltipTitle={'delete'}
//                   onClick={() => handelDeleteRows(index)}
//                 />
//               </td>
//             )}
//           </tr>
//         ))}
//     </tbody>
//   );
// };

// export default TBody;
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import {
  CardIconButton,
  DeleteIconButton,
} from '../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TimePicker } from '@mui/x-date-pickers';
import CustomSelect from '../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import TextAreaInput from '../../../../components/common/TextAreaInput';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
}

const TBody = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const checklistId = useQuery('checklistId')
  const planId = useQuery('planId')
  const docId = useQuery('documentId')
  const month = useQuery('month')
  const date = useQuery('date')
  const a2 = useQuery('machineName')
  const a1 = useQuery('machineNo')
  const a3 = useQuery('departmentName')
  return (
    <tbody>
      {moduleData.histories &&
        moduleData.histories.map((spec: any, index: number) => {
          const isMaintenanceBdYes = spec.maintenance_bd === 'YES';
          const isMaintenancePrevYes = spec.maintenance_prev === 'YES';

          return (
            <tr key={index}>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  formatDate(spec.date)
                ) : (
                  <DatePickerMui
                    value={moment(spec.date)}
                    onChange={(date) => {
                      spec.date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  formatDate(spec.time)
                ) : (
                  <TimePicker
                    value={moment(spec.time)}
                    onChange={(date) => {
                      spec.time = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  spec.maintenance_bd
                ) : (
                  <CustomSelect
                    label=""
                    name="maintenance_bd"
                    value={spec.maintenance_bd}
                    onChange={(e: any) => {
                      spec.maintenance_bd = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="YES">YES</MenuItem>
                    <MenuItem value="NO">NO</MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  spec.maintenance_prev
                ) : (
                  <CustomSelect
                    label=""
                    name="maintenance_prev"
                    value={spec.maintenance_prev}
                    onChange={(e: any) => {
                      spec.maintenance_prev = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="YES">YES</MenuItem>
                    <MenuItem value="NO">NO</MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td style={{ minWidth: '300px' }}>
                <TextAreaInput
                  isView={isView}
                  name="reason_for_maintenance"
                  value={spec.reason_for_maintenance}
                  onChange={(e) => {
                    spec.reason_for_maintenance = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td
                style={{
                  minWidth: '300px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  border: '1px solid #dee2e6', 
                  borderBottom: '1px solid #dee2e6',
                }}
              >
                <TextAreaInput
                  isView={isView}
                  name="action_taken"
                  value={spec.action_taken}
                  onChange={(e) => {
                    spec.action_taken = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
                {isMaintenancePrevYes && (
                  <CardIconButton
                    tooltipTitle="PM Checklist"
                    onClick={() => {
                      navigate(
                        `/common/prevantiveMaintenanceStatusCheckList/${spec?.checklist_id || null}?id=${id}&rowId=${spec?._id}&planId=${planId}&documentId=${docId}&month=${month}&date=${date}&isView=${'false'}&machineName=${a1}&machineNo=${a2}&departmentName=${a3}`
                      );
                    }}
                  />
                )}
              </td>

              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  formatDate(spec.rectification_date)
                ) : (
                  <DatePickerMui
                    value={moment(spec.rectification_date)}
                    onChange={(date) => {
                      spec.rectification_date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  formatDate(spec.rectification_time)
                ) : (
                  <TimePicker
                    value={moment(spec.rectification_time)}
                    onChange={(date) => {
                      spec.rectification_time = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="idle_hr_per_day"
                  type="text"
                  value={spec.idle_hr_per_day}
                  onChange={(e) => {
                    spec.idle_hr_per_day = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="sign"
                  type="text"
                  value={spec.sign}
                  onChange={(e) => {
                    spec.sign = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={spec.remarks}
                  onChange={(e) => {
                    spec.remarks = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px', textAlign: 'center' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          );
        })}
    </tbody>
  );
};

export default TBody;
