import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { IModule } from '../../interface';

interface IProps {
  isView: boolean;
  moduleData: IModule;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={6}
        rowSpan={3}
        labels={['Doc No:', 'Rev No.:', ' Eff. Date:']}
        values={['FRM-QAS-B-005', '00', ' 01-04-2023']}
        heading="Quality Standard"
      />
      <tr>
        <th colSpan={2} align="left">
          Date:
        </th>
        <th colSpan={4} style={{ textAlign: 'left' }}>
          {isView ? (
            formatDate(moduleData.insp_standard_date)
          ) : (
            <DatePickerMui
              value={moment(moduleData.insp_standard_date)}
              onChange={(date) => {
                moduleData.insp_standard_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {/* <th colSpan={3} align="left">
          Part No.:
          {moduleData?.ri_part_id?.part_name || moduleData.partName || ''}{' '}
        </th> */}
        <th colSpan={6} align="left">
          Part Name:
          {moduleData?.ri_part_id?.part_number || moduleData.partNumber || ''}
        </th>
      </tr>
      <tr>
        {[
          'S.No',
          'Parameter',
          'Specification',
          'Method',
          'Remark',
          'Action',
        ].map((heading) => (
          <th key={heading}>{heading}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
