import { HiDocumentReport } from 'react-icons/hi';
import { CgCalendarToday } from 'react-icons/cg';
import { RiDeleteBin4Fill } from 'react-icons/ri';
import { GiExitDoor } from 'react-icons/gi';
import { RenderTiles } from '../../components/common';
import { GrTest, GrUser } from 'react-icons/gr';
import { FaHandsHelping, FaToolbox } from 'react-icons/fa';
import { MdOutlineAssistant, MdOutlineLiveHelp } from 'react-icons/md';
import AdminHomeRenderTiles from './AdminHomeRenderTiles';
import { SiKaios } from 'react-icons/si';
import { MdOutlinePendingActions } from 'react-icons/md';
import { FaDiceD20 } from 'react-icons/fa';
import { VscServerProcess } from 'react-icons/vsc';
import { TbGauge, TbReport } from 'react-icons/tb';

// don't change id sequence in adminTilesObj
// id should be unique

const adminTilesObj = [
  {
    id: 1,
    link: '/admin/clause1',
    isLink: true,
    clauseNo: 1,
    p: 'PRODUCTION PREPARATION /',
    span: 'NEW PRODUCT DEVELOPEMENT',
    isIcon: false,
  },
  {
    id: 111,
    link: '/admin/clause2',
    isLink: true,
    clauseNo: 2,
    p: 'REGULATION FOR INITIAL',
    span: 'PRODUCTION DEVELOPEMENT',
    isIcon: false,
  },
  {
    id: 3,
    link: '/admin/clause3',
    isLink: true,
    clauseNo: 3,
    p: 'CHANGING',
    span: 'MANAGEMENT',
    isIcon: false,
  },
  {
    id: 4,
    link: '/admin/clause4',
    isLink: true,
    clauseNo: 4,
    p: 'STANDARDS',
    span: 'MANAGEMENT',
    isIcon: false,
  },
  {
    id: 506,
    link: '/admin/clause5',
    isLink: true,
    clauseNo: 5,
    p: 'EDUCATION AND',
    span: 'TRAINING',
    isIcon: false,
  },
  {
    id: 16,
    link: '/admin/clause6',
    isLink: true,
    clauseNo: 6,
    p: 'QUALITY AUDIT AND',
    span: 'PROCESS VERIFICATION',
    isIcon: false,
  },
  {
    id: 7,
    link: '/admin/clause7',
    isLink: true,
    clauseNo: 7,
    p: 'Supplier',
    span: 'Control',
    isIcon: false,
  },
  {
    id: 8,
    link: '/admin/clause8',
    isLink: true,
    clauseNo: 8,
    p: 'HANDLING ABNORMILITY',
    span: 'IN QUALITY',
    isIcon: false,
  },
  {
    id: 5,
    link: '/admin/clause9',
    isLink: true,
    clauseNo: 9,
    p: '5S',
    span: 'MANAGEMENT',
    isIcon: false,
  },
  {
    id: 10,
    link: '/admin/clause10',
    isLink: true,
    clauseNo: 10,
    p: 'EQUIPMENT / INSPECTION',
    span: "EQUIPMENT'S MANAGEMENT",
    isIcon: false,
  },
  {
    id: 112,
    link: '/admin/clause11',
    isLink: true,
    clauseNo: 11,
    p: 'IMPLEMENTATION OF',
    span: 'STANDARDS',
    isIcon: false,
  },
  {
    id: 113,
    link: '/admin/clause12',
    isLink: true,
    clauseNo: 12,
    p: 'PRODUCTS',
    span: 'MANAGEMENT',
    isIcon: false,
  },
  {
    id: 114,
    link: '/admin/clause13',
    isLink: true,
    clauseNo: 13,
    p: 'HANDLING',
    span: 'MANAGEMENT',
    isIcon: false,
  },
  {
    id: 115,
    link: '/admin/clause14',
    isLink: true,
    clauseNo: 14,
    p: 'CRITICAL PARTS',
    span: 'MANAGEMENT',
    isIcon: false,
  },
  {
    id: 15,
    link: '/common/clause15',
    isLink: true,
    clauseNo: 15,
    p: 'ADEQUATE TESTING',
    span: 'FACILITY',
    isIcon: false,
  },
  {
    id: 116,
    link: '/admin/clause16',
    isLink: true,
    clauseNo: 16,
    p: 'PROCESS',
    span: 'AUDIT',
    isIcon: false,
  },
  {
    id: 117,
    link: '/admin/clause17',
    isLink: true,
    clauseNo: 17,
    p: 'SCALE AND TOP',
    span: 'MANAGEMENT BANDWIDTH',
    isIcon: false,
  },
  {
    id: 118,
    link: '/admin/clause18',
    isLink: true,
    clauseNo: 18,
    p: 'SAFETY',
    span: '',
    isIcon: false,
  },
  {
    id: 119,
    link: '/admin/clause19',
    isLink: true,
    clauseNo: 19,
    p: 'LEGAL COMPLIANCE',
    span: 'AND ENVIRONMENT',
    isIcon: false,
  },
  {
    id: 166,
    link: '/common/production',
    isLink: true,
    icon: <CgCalendarToday size="5rem" color="#212529" />,
    p: 'PRODUCTION',
    span: 'MANAGEMENT',
    isIcon: true,
  },

  {
    id: 17,
    link: '/common/scrapNoteList',
    isLink: true,
    icon: <RiDeleteBin4Fill size="5rem" color="#a12222" />,
    p: 'SCRAP NOTE',
    span: 'REPORT',
    isIcon: true,
  },
  {
    id: 18,
    link: '/common/riDepartment',
    isLink: true,
    icon: <GiExitDoor size="5rem" color="#a12222" />,
    p: 'RI',
    span: 'DEPARTMENT',
    isIcon: true,
  },
  {
    id: 19,
    link: '/common/processRejection',
    isLink: true,
    icon: <CgCalendarToday size="5rem" color="#212529" />,
    p: 'PROCESS REJECTION',
    span: 'SHEET',
    isIcon: true,
  },
  {
    id: 23,
    link: '/common/pirtiles',
    isLink: true,
    clauseNo: 4,
    p: 'Process Inspection ',
    span: 'Reports',
    icon: <VscServerProcess size="5rem" color="#a12222" />,
    isIcon: true,
  },
  {
    id: 27,
    link: '/admin/pdirList',
    isLink: true,
    icon: <TbReport size="5rem" color="#a12222" />,
    p: 'FINAL',
    span: 'INSPECTION',
    isIcon: true,
  },
  {
    id: 28,
    link: '/common/finalInspectionRejectionRecordsList',
    isLink: true,
    icon: <GrTest size="5rem" color="#1864ab" />,
    p: 'Final Inspection',
    span: 'Rejection Records',
    isIcon: true,
  },
  {
    id: 29,
    link: '/common/finalInspectionRejectionRecordsSuppierTiles',
    isLink: true,
    icon: <GrTest size="5rem" color="#a12222" />,
    p: 'Final Inspection',
    span: 'Rejection Records (Supplier)',
    isIcon: true,
  },
  {
    id: 30,
    link: '/common/materialInspectionRejectionRecordsList',
    isLink: true,
    icon: <GrTest size="5rem" color="#1864ab" />,
    p: 'Material Inspection',
    span: 'Rejection Records',
    isIcon: true,
  },
  {
    id: 433,
    link: `/common/kaizenSubmissionList`,
    isLink: true,
    icon: <SiKaios size="5rem" color="#003566" />,
    p: 'KAIZEN',
    span: 'SUBMISSION',
    isIcon: true,
  },
  {
    id: 25,
    link: '/common/gaugeMaintenanceTiles',
    isLink: true,
    clauseNo: 4,
    icon: <FaToolbox size="5rem" color="#a12222" />,
    p: 'Gauge Maintenance',
    span: 'Plan',
    isIcon: true,
  },
  {
    id: 26,
    link: '/common/gauges/list',
    isLink: true,
    clauseNo: 4,
    icon: <TbGauge size="5rem" color="#a12222" />,
    p: 'Master List Of',
    span: 'Gauges',
    isIcon: true,
  },
  {
    id: 277,
    link: '/common/standardManagementTiles',
    isLink: true,
    clauseNo: 4,
    icon: <MdOutlineAssistant size="5rem" color="#a12222" />,
    p: 'Standard',
    span: 'Management',
    isIcon: true,
  },
  //{
  //  id: 21,
  //  link: `/common/dashboardData`,
  //  isLink: true,
  //  icon: <MdOutlinePendingActions size="5rem" color="#c70000" />,
  //  p: 'PENDING',
  //  span: 'REPORTS',
  //  isIcon: true,
  //},
  {
    id: 20,
    link: `/common/mushinHelpDesk`,
    isLink: true,
    icon: <MdOutlineLiveHelp size="5rem" color="#003566" />,
    p: 'MUSHIN HELP',
    span: 'DESK',
    isIcon: true,
  },
  {
    id: 21,
    link: `/common/dashboardData?endpoint=pendingReportsRoute`,
    isLink: true,
    icon: <MdOutlinePendingActions size="5rem" color="#c70000" />,
    p: 'PENDING',
    span: 'REPORTS',
    isIcon: true,
  },
  {
    id: 22,
    link: `/common/DashboardPage`,
    isLink: true,
    icon: <FaDiceD20 size="5rem" color="#c70000" />,
    p: 'DASHBOARD',
    span: '',
    isIcon: true,
  },
];

const validateUniqueIds = (tiles: any) => {
  const seenIds = new Set();
  tiles.forEach((tile: any) => {
    if (seenIds.has(tile.id)) {
      throw new Error(
        `Duplicate ID detected for "${tile.p} ${tile.span}". Each ID must be unique. Please update the ID for this tile without modifying existing IDs.`,
      );
    }
    seenIds.add(tile.id);
  });
};

const AdminHome = () => {
  // Validate IDs during component initialization
  try {
    validateUniqueIds(adminTilesObj);
  } catch (error) {
    console.error(error);
    // This will crash the application
    throw error;
  }

  return (
    <AdminHomeRenderTiles
      tilesObj={adminTilesObj}
      justify="center"
      // height="100vh"
      // columns="0fr"
    />
  );
};

export default AdminHome;
