export const listConstant = {
  childHeader: 'PRODUCT AUDIT',
  addButtonLable: 'NEW PRODUCT AUDIT',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PRODUCT AUDIT',
};

export const createConstant = {
  childHeader: 'PRODUCT AUDIT PLAN',
  theadHeader: 'PRODUCT AUDIT PLAN',
  extracolSpan: 3,
};

export const checkListList = {
  childHeader: 'PRODUCT AUDIT PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'productAuditPlanRoute',
  gettableDataurl: 'productAuditPlanRoute/monthly',
  getPlanByid: 'productAuditPlanRoute',
  createPlanByid: 'productAuditPlanRoute',
  updatePlanByid: 'productAuditPlanRoute',
  sync: 'productAuditPlanRoute/handleSync',
  getAllCheckList: 'productAuditPlanRoute/getAllCheckList',
  checkListStatus: 'productAuditPlanRoute/handleStatus',
  checklistUpload: 'productAuditPlanRoute/handleUpload',
  checklistDeleteImage: 'productAuditPlanRoute/handleDeleteImage',
  checklistData: 'productAuditPlanRoute/checklistRoute',
  upadateCreateAllPlan: 'productAuditPlanRoute/allplansCreateUpdate',

};

export const navigationConstant = {
  createPlanSchedule: '/common/productAuditPlanCreate',
  createChecklist: '/common/productAuditPlanChecklistCreate',
};
