import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';

import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import InputMuiCustom from '../../../../components/common/inputs/InputMuiCustom';
import FormControlMui, {
  StyledField,
} from '../../../../components/common/FormControlMui';
import useConfirm from '../../../../components/common/useConfirm';
import CustomUploadButton from '../../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../../components/common/Loader';
import { useQuery } from '../../../../hooks/UseQuery';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
interface Iprop {
  setIsOpenPopup: Dispatch<SetStateAction<boolean>>;
  id?: string | object | null;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
}

interface IData {
  _id?: string;
  drawing_name: null | string;
  remarks: null | string;
  rev_no: null | number;
  checked_on?: Date;
  description?: string;
  createdAt?: Date;
}
const CreateDrawing = ({ setIsOpenPopup, id, setId }: Iprop) => {
  const documentType = useQuery('documentType') || '';
  const [data, setData] = useState<IData>({
    drawing_name: null,
    rev_no: null,
    remarks:null,
    checked_on: new Date(),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState(null);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'SUBMIT DOCUMENT',
    `Are you sure you want to ${id ? 'update' : 'save'} this document?`,
  );

  const handlePartData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/documentStandard/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      setData((prev) => prev);
    }
  }, [id]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) return;
    let formData = new FormData();
    setIsLoading(true);
    if (documentType) formData.append('document_type', documentType);
    if (data.drawing_name) formData.append('drawing_name', data.drawing_name);
    if (data.rev_no) formData.append('rev_no', data.rev_no.toString());
    if (data.remarks) formData.append('remarks', data.remarks);
    if (data.checked_on)
      formData.append('checked_on', data.checked_on.toString());
    if (data.description) formData.append('description', data.description);
    if (files) {
      for (const key of Object.keys(files)) {
        formData.append('files', files[key]);
      }
    }
    if (id) {
      await axios
        .put(`/api/documentStandard/${id}`, formData)
        .then((res) => {
          setIsLoading(false);
          setIsOpenPopup(false);
          setId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      await axios
        .post('/api/documentStandard', formData)
        .then((res) => {
          setIsLoading(false);
          setIsOpenPopup(false);
          setId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <Box>
        <FormControlMui handleSubmit={onSubmit} style={{ height: '200px' }}>
          <div
            className="btn_save"
            style={{
              position: 'absolute',
              right: '140px',
              top: '-0.6px',
            }}
          >
            <SubmitButton
              label={`${id ? 'update' : 'submit'}`}
              type="submit"
              disabled={isLoading}
            />
          </div>
          <StyledField>
            <InputMuiCustom
              type="text"
              name="drawing_name"
              placeholder="Document Name"
              required={true}
              onChange={handlePartData}
              value={data?.drawing_name || ''}
            />
            <InputMuiCustom
              type="number"
              name="rev_no"
              placeholder="Rev No."
              onChange={handlePartData}
              value={data?.rev_no || ''}
            />
            <InputMuiCustom
              type="text"
              name="description"
              placeholder={
                documentType === 'wiUpload' ? 'Process' : 'Document No'
              }
              onChange={handlePartData}
              value={data?.description || ''}
            />
            <InputMuiCustom
              type="text"
              name="remarks"
              placeholder={'Remarks'}
              onChange={handlePartData}
              value={data?.remarks || ''}
            />
            {/* <DatePickerMui
              label="Checked On"
              onChange={(date) => {
                data.checked_on = date;
                setRender((prev) => !prev);
              }}
              value={moment(data.checked_on)}
            /> */}
            <CustomUploadButton
              lable={'UPLOAD DOCUMENT'}
              multiple
              accept="image/png,image/jpeg,image/jpg,application/pdf,.xlsx,.xls,.doc,.docx"
              onChange={(e) => setFiles(e.target.files)}
            />
          </StyledField>
        </FormControlMui>
      </Box>
      <DialogSubmit />
    </>
  );
};

export default CreateDrawing;
