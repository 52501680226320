import React, { useEffect, useState } from 'react';
import TController from './components/view/TController';
import { IData ,IData1} from './helpers/interfaces';
import { initialState } from './helpers/initialState';
import lodash from 'lodash';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from "../../../components/ui/ChildHeader";

import {
  CustomButton,
  SaveButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { useAuth } from '../../../services/auth/AuthProvider';
import { useQuery } from "../../../hooks/UseQuery";
import { processes } from './helpers/initialState';
import ModalCustom from '../../../components/common/Modal';
import useConfirm from '../../../components/common/useConfirm';
import CustomSelect from '../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import ViewFile from './helpers/ViewFile';
import { ViewIconButton } from './helpers/ViewIconButton';

const View = () => {
  const [moduleData, setModuleData] = useState<IData1>(
    lodash.cloneDeep(initialState)
  );
  const [render, setRender] = useState(false);
  const [partIdx, setPartIdx] = useState(false);
  const navigate = useNavigate();
  const { state } = useAuth();
  const { id,idx } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [isOpenPopup, setIsOpenPopup] = useState(false);
 const [id1, setId1] = useState<string | null>(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
  const [contentType, setContentType] = useState('');
 
  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/productAuditPlanRoute/getById/${id}/${idx}`)
        .then((res) => {
          console.log("res.data is",res?.data);
          // if(res?.data?.data?.nc_minutes)
          if(res?.data?.data?.summary[0].nc_minutes === ""){

            res.data.data.summary[0].nc_minutes= moduleData?.nc_minutes;
          }
          setModuleData(res?.data?.data?.summary[0]);
        })
        .catch((err) => console.log(err));
    }
  }, [id,render]);

  const handleSumbit = async (isSubmitted: boolean) => {
    const ans = await confirmSubmit();
    if (!ans) return;
    moduleData.isSubmitted = isSubmitted;
    console.log("data before submit ",moduleData);
    //moduleData.is_admin_request = 'pending';
    //moduleData.report_prepared_by = state.user?.name;
    if (id) {
      await axios
        .put(`/api/productAuditPlanRoute/updateById/${id}/${idx}`, moduleData)
        .then((res) => {
          if (res?.data?.success) {
            alert('Updated Successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/productAuditPlanRoute', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const uploadFile = async (e: any, item: any) => {
    if (!window.confirm('Are you sure you want upload this Files')) return;
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    for (const key of Object.keys(files)) {
      formData.append('files', files[key]);
    }
    // setIsLoading(true);
    axios
      .patch(`/api/productAuditPlanRoute/summary/handleUpload/${item}/${idx}`, formData)
      .then((res) => {
        if (res.data) {
          alert('document upload successly');
          // setIsLoading(false);
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        // setIsLoading(false);
        console.log(err);
      });
  };

  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.processes.splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <>
      <Box>
        <ChildHeader text="NC REPORT">
          {!isView && (
            <>
              <CustomUploadButton
                          lable={'Upload'}
                          id={`_iamges`}
                          multiple
                          accept="image/png,image/jpeg,image/jpg"
                          onChange={(e) => {
                            uploadFile(e, id);
                          }}
                        />
                        {moduleData?.files && moduleData.files.length > 0 && (
                          <ViewIconButton
                          sx={{color:'white'}}
                            tooltipTitle="view"
                            onClick={() => {
                              setId1(id || null);
                              setIsFileOpenPopup(true);
                              setContentType('file');
                            }}
                          />
                        )}

              <SubmitButton label="Submit" onClick={() => handleSumbit(true)} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handleSumbit,
          handelDeleteRows,
          isOpenPopup,
          setIsOpenPopup,
          setPartIdx,
          setModuleData,
        }}
      />

<ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId1(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile
        render={render}
        setRender={setRender}
         idx={idx} imageId={id} contentType={contentType} />
      </ModalCustom>
      <DialogDeleteRow isSubmitContent={false} />
      <DialogAddRow />
      <DialogSubmit />
    </>
  );
};

export default View;
