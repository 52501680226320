import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { Checkbox } from '@mui/material';
// import "../../../../App.css";
import TipTap from '../../TipTap';
import { parseUrl } from 'query-string/base';
import HTMLReactParser from 'html-react-parser';
import { TableInput } from '../../../../../components/common';
import { CustomButton } from '../../../../../components/common/button/CustomButtons';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
interface IProps {
  moduleData: any;
  isView: boolean;
  setModuleData: any;
}

const THead = ({ moduleData, isView, setModuleData }: IProps) => {
  const [render, setRender] = useState(false);
  const [agenda, setAgenda] = useState('');
  const [invitees, setInvitees] = useState('');

  const handleSetDesc = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      details_of_nc: desc,
    }));
  };
  const handleSetDescObj = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      details_of_desc: desc,
    }));
  };

  const handleSetDescListInvitees = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      correction: desc,
    }));
  };
  const handleSetDescListInvitees1 = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      root_cause: desc,
    }));
  };
 

  // const handleInputChange = (name: string, value: string) => {
  //   setModuleData((prevData: any) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };
  // const handleInputChange = (name: string, value: string, index?: number) => {
  //   setModuleData((prevData: any) => {
  //     // If index is provided, update the specific item in auditeeRow
  //     if (typeof index === 'number') {
  //       const updatedAuditeeRow = [...prevData.auditeeRow];
  //       // Update the specific field in the targeted row
  //       updatedAuditeeRow[index] = {
  //         ...updatedAuditeeRow[index],
  //         [name]: value,
  //       };
  
  //       return {
  //         ...prevData,
  //         auditeeRow: updatedAuditeeRow, // Update auditeeRow with modified data
  //       };
  //     }
  
  //     // For other fields, update them directly in the moduleData
  //     return {
  //       ...prevData,
  //       [name]: value,
  //     };
  //   });
  // };
  
  const handleInputChange = (name: string, value: string, index?: number,arrayName?: string) => {
    setModuleData((prevData: any) => {
      // If arrayName and index are provided, update the specific item in the array
      if (arrayName && typeof index === 'number') {
        const updatedArray = [...prevData[arrayName]];
        // Update the specific field in the targeted row
        updatedArray[index] = {
          ...updatedArray[index],
          [name]: value,
        };
  
        return {
          ...prevData,
          [arrayName]: updatedArray, // Dynamically update the targeted array
        };
      }
  
      // For fields outside of arrays, update them directly in the moduleData
      return {
        ...prevData,
        [name]: value,
      };
    });
  };
  
  // const handelDeleteRows = (index: any,item:any) => {
  //   setModuleData((prevData: any) => {
  //     const updatedAuditeeRow = [...prevData.auditeeRow];
  //     updatedAuditeeRow.splice(index, 1);
  
  //     return {
  //       ...prevData,
  //       auditeeRow: updatedAuditeeRow, 
  //     };
  //   });
  // };
  
  const handelDeleteRows = (index: number,arrayName: string) => {
    setModuleData((prevData: any) => {
      const updatedArray = [...prevData[arrayName]]; // Access the dynamic array
      updatedArray.splice(index, 1); // Remove the item at the specified index
  
      return {
        ...prevData,
        [arrayName]: updatedArray, // Dynamically update the array in the state
      };
    });
  };
  

  return (
    <thead>
      {/* <TableCompHead
        colSpan={7}
        rowSpan={3}
        labels={['Doc No.:', 'Rev No.:', 'Eff. Date:']}
        values={['F-MR-06 ', '03', '01-04-2019']}
        heading="NON-CONFORMANCE REPORT"
      /> */}

      <tr>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '40px',
          }}
        ></th>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '150px',
          }}
        ></th>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '150px',
          }}
        ></th>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '150px',
          }}
        ></th>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '150px',
          }}
        ></th>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '50px',
          }}
        ></th>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '100px',
          }}
        ></th>
      </tr>

   
      <tr style={{ border: 'none' }}>
        <th colSpan={2} style={{ border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              // borderRight: 'none',
              // borderBottom: 'none',
              fontSize: '18px',
            }}
          >
           Non- Conformity Details
          </div>
        </th>
        <td colSpan={5} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="details_of_nc"
              type="text"
              value={moduleData?.details_of_nc}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
      
        
      </tr>

      <tr style={{ border: 'none' }}>
        <th colSpan={2} style={{ border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              // borderRight: 'none',
              // borderBottom: 'none',
              fontSize: '18px',
            }}
          >
           Description of Objective Evidence
          </div>
        </th>
        <td colSpan={5} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="details_of_desc"
              type="text"
              value={moduleData?.details_of_desc}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
        
      </tr>

     

      <tr style={{ border: 'none' }}>
        <th colSpan={2} style={{ border:'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              border:'none',
              // borderRight: 'none',
              // borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            Auditor Sign  
          </div>
        </th>
        <td colSpan={2} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="auditor_sign"
              type="text"
              value={moduleData?.auditor_sign}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
        <th colSpan={1} style={{ border:'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              border:'none',
              // borderRight: 'none',
              // borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            Dept. Rep. Sign 
          </div>
        </th>
        <td colSpan={2} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="dept_rep_sign"
              type="text"
              value={moduleData?.dept_rep_sign}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
        
        
      </tr>
{/* 
      <tr style={{ border: 'none' }}>
        <th colSpan={7} style={{ borderRight: 'none', border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              border: 'none',
              fontSize: '18px',
            }}
          >
Root Cause Analysis (Do why-why analysis to identify the root cause)          </div>
        </th>
      </tr> */}

      {/* <tr style={{ border: 'none' }}>
        {isView ? (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap">
              {HTMLReactParser(moduleData?.root_cause)}
            </div>
          </td>
        ) : (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap" style={{ columnSpan: 'all' }}>
              <TipTap
                setDesc={handleSetDescListInvitees}
                initialContent={moduleData?.root_cause}
              />
            </div>
          </td>
        )}
      </tr> */}
      <tr style={{ border: 'none' }}>
      <th align='center' colSpan={5} style={{ borderRight: 'none', borderBottom: 'none' }}>
          <div
            style={{
              // marginLeft: '4px',
              textAlign: 'center',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            TO BE COMPLITED BY THE AUDITEE 
          </div>
        </th>
        <th
          colSpan={2}
          style={{
            borderRight: 'none',
            borderLeft: 'none',
            borderBottom: 'none',
          }}
        >
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'right',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              borderLeft: 'none',
              borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            {/* <CustomButton
                           sx={{
                             backgroundColor: '#1c7ed6',
                             margin:'18px',
                           }}
                           onClick={async () => {
                             // const ans = await confirmAddRow();
                             // if (!ans) return;
                            //  setModuleData((prevData: any) => {
                            //    const updatedSummary = [...prevData.summary, {}]; // Add the new row
                            //    return {
                            //      ...prevData,
                            //      summary: updatedSummary, // Update the summary with the new row
                            //    };
                            //  });
                             moduleData.auditeeRow.push({});
                             // filteredSummary.push({});
                             setRender((prev) => !prev);
                           }}
                         >
                           ADD ROWS
                         </CustomButton> */}
          </div>
        </th>
      </tr>
      <tr>

      {
        ['Sr. No',
          'Correction: (What is done to solve the specified NC ?)',
          // 'Root Cause Analysis (Do why-why analysis to identify the root cause)',
          // 'Corrective Action (What is done to prevent recurrence?)',
          // 'Is there any scope for Horizontal Deployment ?',
        // 'Action',
        'Add Row',
      ].map((item:any,index:number)=>{
          return(
            // <tr>
           
            <th colSpan={(index==1)?3:2} onClick={() => {
              if (item === 'Add Row') {
                setModuleData((prevData: any) => ({
                  ...prevData,
                  correctionRow: [...(prevData.correctionRow || []), {}],
                }));
                
              }
            }}
            style={{
              backgroundColor: item === 'Add Row' ? '#1c7ed6' : 'inherit',
              color: item === 'Add Row' ? 'white' : 'inherit',
              cursor: item === 'Add Row' ? 'pointer' : 'default',
            }}>
              {item}
            </th>
               
            // </tr>
          )
        })
      }
      </tr>
      {
        moduleData?.correctionRow?.map((item:any,idx:any)=>{
          return(
            <>
            <tr>
          
            <td colSpan={2}>
              {idx+1}
            </td>
            <td colSpan={3}>
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="correction"
              type="text"
              value={item?.correction}
              onChange={(e) => handleInputChange(e.target.name, e.target.value,idx,'correctionRow')}

            />
            </td>
            {/* <td>
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="root_cause"
              type="text"
              value={item?.root_cause}
              onChange={(e) => handleInputChange(e.target.name, e.target.value,idx)}

            />
            </td>
            <td>
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="corr_action"
              type="text"
              value={item?.corr_action}
              onChange={(e) => handleInputChange(e.target.name, e.target.value,idx)}

            />
            </td>
            <td>
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="scope"
              type="text"
              value={item?.scope}
              onChange={(e) => handleInputChange(e.target.name, e.target.value,idx)}

            />
            </td> */}
            {!isView && (
                            <td colSpan={2} style={{ minWidth: '100px', textAlign: 'center' }}>
                              <DeleteIconButton
                                tooltipTitle={'delete'}
                                onClick={() => handelDeleteRows(idx,'correctionRow')}
                              />
                            </td>
                          )}
                              
            </tr>
            </>
          )
        })
      }
      <tr>
      {
        ['Sr. No',
          // 'Correction: (What is done to solve the specified NC ?)',
          'Root Cause Analysis (Do why-why analysis to identify the root cause)',
          // 'Corrective Action (What is done to prevent recurrence?)',
          // 'Is there any scope for Horizontal Deployment ?',
        // 'Action',
        'Add Row',
      ].map((item:any,index:number)=>{
          return(
            // <tr>
           
            <th colSpan={(index==1)?3:2} onClick={() => {
              if (item === 'Add Row') {
                setModuleData((prevData: any) => ({
                  ...prevData,
                  rootCauseRow: [...(prevData.rootCauseRow || []), {}],
                }));
                
              }
            }}
            style={{
              backgroundColor: item === 'Add Row' ? '#1c7ed6' : 'inherit',
              color: item === 'Add Row' ? 'white' : 'inherit',
              cursor: item === 'Add Row' ? 'pointer' : 'default',
            }}>
              {item}
            </th>
               
            // </tr>
          )
        })
      }
      </tr>
      {
        moduleData?.rootCauseRow?.map((item:any,idx:any)=>{
          return(
            <>
            <tr>
          
            <td colSpan={2}>
              {idx+1}
            </td>
            <td colSpan={3}>
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="root_cause"
              type="text"
              value={item?.root_cause}
              onChange={(e) => handleInputChange(e.target.name, e.target.value,idx,'rootCauseRow')}

            />
            </td>
            {/* <td>
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="root_cause"
              type="text"
              value={item?.root_cause}
              onChange={(e) => handleInputChange(e.target.name, e.target.value,idx)}

            />
            </td>
            <td>
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="corr_action"
              type="text"
              value={item?.corr_action}
              onChange={(e) => handleInputChange(e.target.name, e.target.value,idx)}

            />
            </td>
            <td>
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="scope"
              type="text"
              value={item?.scope}
              onChange={(e) => handleInputChange(e.target.name, e.target.value,idx)}

            />
            </td> */}
            {!isView && (
                            <td colSpan={2} style={{ minWidth: '100px', textAlign: 'center' }}>
                              <DeleteIconButton
                                tooltipTitle={'delete'}
                                onClick={() => handelDeleteRows(idx,'rootCauseRow')}
                              />
                            </td>
                          )}
                              
            </tr>
            </>
          )
        })
      }
      <tr>

     
      {
        ['Sr. No',
          // 'Correction: (What is done to solve the specified NC ?)',
          // 'Root Cause Analysis (Do why-why analysis to identify the root cause)',
          'Corrective Action (What is done to prevent recurrence?)',
          // 'Is there any scope for Horizontal Deployment ?',
        // 'Action',
        'Add Row',
      ].map((item:any,index:number)=>{
          return(
            <th colSpan={(index==1)?3:2} onClick={() => {
              if (item === 'Add Row') {
                setModuleData((prevData: any) => ({
                  ...prevData,
                  corrActionRow: [...(prevData.corrActionRow || []), {}],
                }));
                
              }
            }}
            style={{
              backgroundColor: item === 'Add Row' ? '#1c7ed6' : 'inherit',
              color: item === 'Add Row' ? 'white' : 'inherit',
              cursor: item === 'Add Row' ? 'pointer' : 'default',
            }}>
              {item}
            </th>
          )
        })
      }
       </tr>
      {
        moduleData?.corrActionRow?.map((item:any,idx:any)=>{
          return(
            <>
            <tr>
          
            <td colSpan={2}>
              {idx+1}
            </td>
            <td colSpan={3}>
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="corr_action"
              type="text"
              value={item?.corr_action}
              onChange={(e) => handleInputChange(e.target.name, e.target.value,idx,'corrActionRow')}

            />
            </td>
            {/* <td>
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="root_cause"
              type="text"
              value={item?.root_cause}
              onChange={(e) => handleInputChange(e.target.name, e.target.value,idx)}

            />
            </td>
            <td>
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="corr_action"
              type="text"
              value={item?.corr_action}
              onChange={(e) => handleInputChange(e.target.name, e.target.value,idx)}

            />
            </td>
            <td>
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="scope"
              type="text"
              value={item?.scope}
              onChange={(e) => handleInputChange(e.target.name, e.target.value,idx)}

            />
            </td> */}
            {!isView && (
                            <td colSpan={2} style={{ minWidth: '100px', textAlign: 'center' }}>
                              <DeleteIconButton
                                tooltipTitle={'delete'}
                                onClick={() => handelDeleteRows(idx,'corrActionRow')}
                              />
                            </td>
                          )}
                              
            </tr>
            </>
          )
        })
      }
      <tr>
      {
        ['Sr. No',
          // 'Correction: (What is done to solve the specified NC ?)',
          // 'Root Cause Analysis (Do why-why analysis to identify the root cause)',
          // 'Corrective Action (What is done to prevent recurrence?)',
          'Is there any scope for Horizontal Deployment ?',
        // 'Action',
        'Add Row',
      ].map((item:any,index:number)=>{
          return(
            <th colSpan={(index==1)?3:2} onClick={() => {
              if (item === 'Add Row') {
                setModuleData((prevData: any) => ({
                  ...prevData,
                  scopeRow: [...(prevData.scopeRow || []), {}],
                }));
                
              }
            }}
            style={{
              backgroundColor: item === 'Add Row' ? '#1c7ed6' : 'inherit',
              color: item === 'Add Row' ? 'white' : 'inherit',
              cursor: item === 'Add Row' ? 'pointer' : 'default',
            }}>
              {item}
            </th>
          )
        })
      }
      </tr>
      {
        moduleData?.scopeRow?.map((item:any,idx:any)=>{
          return(
            <>
            <tr>
          
            <td colSpan={2}>
              {idx+1}
            </td>
            <td colSpan={3}>
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="scope"
              type="text"
              value={item?.scope}
              onChange={(e) => handleInputChange(e.target.name, e.target.value,idx,'scopeRow')}

            />
            </td>
            {/* <td>
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="root_cause"
              type="text"
              value={item?.root_cause}
              onChange={(e) => handleInputChange(e.target.name, e.target.value,idx)}

            />
            </td>
            <td>
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="corr_action"
              type="text"
              value={item?.corr_action}
              onChange={(e) => handleInputChange(e.target.name, e.target.value,idx)}

            />
            </td>
            <td>
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="scope"
              type="text"
              value={item?.scope}
              onChange={(e) => handleInputChange(e.target.name, e.target.value,idx)}

            />
            </td> */}
            {!isView && (
                            <td colSpan={2} style={{ minWidth: '100px', textAlign: 'center' }}>
                              <DeleteIconButton
                                tooltipTitle={'delete'}
                                onClick={() => handelDeleteRows(idx,'scopeRow')}
                              />
                            </td>
                          )}
                              
            </tr>
            </>
          )
        })
      }

      <tr style={{ border: 'none' }}>
        <th colSpan={5} style={{  border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              border:'none',
              fontSize: '18px',
            }}
          >
            TARGET DATE
          </div>
        </th>
        <th
          colSpan={1}
          style={{
            border: 'none',
          }}
        >
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'right',
              paddingTop: '4px',
              paddingBottom: '4px',
              border: 'none',
              fontSize: '18px',
            }}
          >
           
          </div>
        </th>

        <th
          colSpan={1}
          align="left"
          style={{ border: 'none'}}
        >
          {isView ? (
            formatDate(moduleData.target_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.target_date)}
              onChange={(date) => {
                moduleData.target_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>

      <tr>
      <th
          colSpan={2}
          style={{
            border:'none'
          }}
        >
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'right',
              paddingTop: '4px',
              paddingBottom: '4px',
              border:'none',
              fontSize: '18px',
            }}
          >
            DATE :
          </div>
        </th>

        <th
          colSpan={2}
          align="left"
          style={{ border:'none' }}
        >
          {isView ? (
            formatDate(moduleData.target_date_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.target_date_date)}
              onChange={(date) => {
                moduleData.target_date_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>

        
        <th colSpan={1} style={{borderRight:'none',border:'none' }}>
          <div style={{marginLeft:'4px', textAlign:'left', paddingTop:'4px', paddingBottom:'4px', borderRight:'none', border:'none', fontSize:'18px'}}>
          HOD Sign
          </div>
        </th>
        <td colSpan={2} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="hod_sign"
              type="text"
              value={moduleData?.hod_sign}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
      </tr>


    </thead>
  );
};

export default THead;
