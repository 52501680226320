import { useState } from 'react';
import { TableInput, TextAreaInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import {
  AddIconButton,
  CardIconButton,
  DeleteIconButton,
  RemoveIconButton,
  UploadImageIconButton,
  ViewIconButton,
} from '../../../../components/common/button/CustomIconButton';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import formatDate from '../../../../components/common/formatDate';
import { TimePicker } from '@mui/x-date-pickers';
import CustomSelect from '../../../../components/common/SelectField';
import { MenuItem, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomUploadButton from '../../../../components/common/button/CustomUploadButton';
import ModalCustom from '../../../../components/common/Modal';
import ViewFile from '../helpers/ViewFile';
import axios from 'axios';
import { moduleConstant } from '../constant';

interface IProps {
  item: any;
  isView: any;
  handleDelete: any;
  index: number;
  machineData: any;
  uploadImageFile: any;
  setRenderforUpdate: any;
}

const TBodyRow = ({
  item,
  index,
  isView,
  handleDelete,
  machineData,
  setRenderforUpdate,
  uploadImageFile,
}: IProps) => {
  const [rowRender, setRowRender] = useState(false);
  const navigate = useNavigate();
  const handleChange = (e: any) => {
    item[e.target.name] = e.target.value;
    setRowRender((prev: any) => !prev);
  };
   const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
    const [contentType, setContentType] = useState('');
     const [id, setId] = useState<string | null>(null);
   
     const uploadFile = async (e: any, item: any) => {
      if (!window.confirm('Are you sure you want upload this Files')) return;
      const files = e.target.files;
      if (!files) return;
      let formData = new FormData();
      for (const key of Object.keys(files)) {
        formData.append('files', files[key]);
      }
      // setIsLoading(true);
      axios
        .patch(`${moduleConstant.apiConstant}/uploadFile/${item}`, formData)
        .then((res:any) => {
          if (res?.data) {
            alert('document upload successly');
            // setIsLoading(false);
            setRenderforUpdate((prev:any) => !prev);
          }
        })
        .catch((err) => {
          // setIsLoading(false);
          console.log(err);
        });
    };

  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td style={{ textAlign: 'center', minWidth: '160px' }}>
          <DatePickerMui
            label=""
            onChange={(date) => {
              item.breakdown_date = moment(date || null);
              setRowRender((prev: any) => !prev);
            }}
            value={
              (item?.breakdown_date && moment(item?.breakdown_date)) || null
            }
          />
        </td>
        <td style={{ minWidth: '150px' }}>
          {isView ? (
            formatDate(item.breakdown_time)
          ) : (
            <TimePicker
              value={moment(item.breakdown_time)}
              onChange={(date) => {
                item.breakdown_time = date;
                setRowRender((prev) => !prev);
              }}
            />
          )}
        </td>
        <td style={{ minWidth: '160px' }}>
          <AutocompleteMuiCustom
            id="machine_id_5"
            label=""
            option_name="machine_no"
            arrayofObj={machineData}
            value={item.machine_id || ''}
            onChange={(e, value) => {
              item.machine_id = value;
              setRowRender((prev: any) => !prev);
            }}
            sx={{ width: 200, margin: 2 }}
          />
        </td>
        <td style={{ minWidth: '160px' }}> {item?.machine_id?.machine_name}</td>
        <td style={{ textAlign: 'center', minWidth: '200px', width: '200px' }}>
          <TextAreaInput
            isView={isView}
            name="reason"
            value={item?.reason ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center', minWidth: '200px', width: '200px' }}>
          <TextAreaInput
            isView={isView}
            name="action_taken"
            value={item?.action_taken ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center', minWidth: '160px' }}>
          <DatePickerMui
            label=""
            onChange={(date) => {
              item.bd_rectified_date = moment(date || null);
              setRowRender((prev: any) => !prev);
            }}
            value={
              (item?.bd_rectified_date && moment(item?.bd_rectified_date)) ||
              null
            }
          />
        </td>
        <td style={{ minWidth: '150px' }}>
          {isView ? (
            formatDate(item.bd_rectified_time)
          ) : (
            <TimePicker
              value={moment(item.bd_rectified_time)}
              onChange={(date) => {
                item.bd_rectified_time = date;
                setRowRender((prev) => !prev);
              }}
            />
          )}
        </td>
        <td style={{ textAlign: 'center', width: '150px', minWidth: '150px' }}>
          <CustomSelect
            name="dropdown"
            value={item.status || ''}
            onChange={(e) => {
              item.status = e.target.value;
              setRowRender((prev) => !prev);
            }}
            sx={{
              minWidth: '90px',
              textAlign: 'center',
            }}
          >
            <MenuItem value="">NONE</MenuItem>
            <MenuItem value="OK">OK</MenuItem>
            <MenuItem value="NOT OK">NOT OK</MenuItem>
          </CustomSelect>
        </td>
        <td style={{ textAlign: 'center', width: '150px', minWidth: '150px' }}>
          <TableInput
            isView={isView}
            type="text"
            name="idle_time"
            value={item?.idle_time ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center', width: '150px', minWidth: '150px' }}>
          <TableInput
            isView={isView}
            type="text"
            name="sign"
            value={item?.sign ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="remarks"
            value={item?.remarks ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td>
          {item._id && (
            // <Stack
            //   direction="row"
            //   sx={{ justifyContent: 'center', alignItems: 'center' }}
            // >
            //   <UploadImageIconButton
            //     id={`${index}-process-upload`}
            //     tooltipTitle="upload"
            //     onChange={(e) => {
            //       uploadImageFile(e, item._id);
            //     }}
            //   />
            //   {item.files?.length > 0 && (
            //     <>
            //       <a target={'_blank'} href={item.files[0]?.url}>
            //         <ViewIconButton tooltipTitle={'view'} onClick={() => {}} />
            //       </a>
            //       <DeleteIconButton
            //         tooltipTitle={'delete'}
            //         onClick={() => {
            //           item.files = [];
            //           setRowRender((prev) => !prev);
            //         }}
            //       />
            //     </>
            //   )}
            // </Stack>
            <Stack direction="row" sx={{ justifyContent: 'center' }}>
            <CustomUploadButton
              lable={''}
              id={`${index}_iamges`}
              multiple
              accept="image/png,image/jpeg,image/jpg"
              onChange={(e) => {
                uploadFile(e,item._id)
                  // uploadImageFile(e, item._id);
          }}
            />
            {item.files && item.files.length > 0 && (
              <ViewIconButton
                tooltipTitle="view"
                onClick={() => {
                  setId(item._id || null);
                  setIsFileOpenPopup(true);
                  setContentType('file');
                }}
              />
            )}
          </Stack>
          )}
        </td>
        <td>
          {item?._id && (
            <CardIconButton
              tooltipTitle="Breakdown Analysis"
              onClick={() => {
                navigate(
                  `/common/machineBreakdownAnalysis/${item?._id}?machine_name=${item?.machine_id?.machine_name}&machine_no=${item?.machine_id?.machine_no}`
                );
              }}
            />
          )}
        </td>
        {!isView && (
          <td style={{ textAlign: 'center' }}>
            <DeleteIconButton
              tooltipTitle="DELETE"
              onClick={() => {
                handleDelete(item?._id, index);
              }}
            />
          </td>
        )}
      </tr>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile setRenderforUpdate={setRenderforUpdate} id={id} contentType={contentType} />
      </ModalCustom>
    </>
  );
};

export default TBodyRow;
