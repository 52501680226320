import React , { SetStateAction, useState, useEffect } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import { Grid, MenuItem, Tooltip, Button, Box } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { TimePicker } from '@mui/x-date-pickers';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import { convToTime } from '../../../../../utils/helpers';
import CustomMobileTimePickerMui from '../../../../../components/common/CustomMobileTimePickerMui';
import Operator from '../../../components/Operator';
import OperationSelect from '../OperationSelect';
import convToTime24 from '../../../../../utils/helpers/convToTime';
import ModalCustom from '../../../../../components/common/Modal';
import OperatorFor4mLogs from './OperatorFor4mLogs';
import DowntimeReasonModal from './DowntimeReasonModal';
import { useQuery } from '../../../../../hooks/UseQuery';


interface IProps {
  moduleData: any;
  isView: boolean;
  handleSumbit: any;
  machineData: any;
  autoCompleteMachineValue: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const TBody = ({
  moduleData,
  isView,
  handleSumbit,
  machineData,
  autoCompleteMachineValue,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [isOpen4mModel, setIsOpen4mModel] = useState(false);
  const [modalIdx, setModalIdx] = useState<any>(null);
  const [isDowntimeModalOpen, setIsDowntimeModalOpen] = useState(false);
  const [isFreeze, setIsFreeze] = useState(false);
  const [currentProcessIndex, setCurrentProcessIndex] = useState<number | null>(null);
  const documentType = useQuery('documentType') || moduleData.document_type;

  const calculatePlanQty = (process: any) => {
    // NO CALCULATION FOR WELD SHOP
    if(documentType === 'pressShop3') return;


    const cycleTime = process.cycle_time || null;
    if(cycleTime === null || cycleTime ===  undefined || cycleTime === 0){
      return null
    }

    if(!process.time_start || !process.time_stop){
      return null
    }
    
    if (cycleTime && process.time_start && process.time_stop) {
      const startTime = moment(process.time_start);
      const stopTime = moment(process.time_stop);
      //const durationInSeconds = stopTime.diff(startTime, 'seconds');
      //return Math.floor(durationInSeconds / cycleTime);
      let durationInMinutes = stopTime.diff(startTime, 'minutes');
      if (durationInMinutes < 0) {
        durationInMinutes += 1440;
      }
      durationInMinutes -= ((process.total_downtime && process.total_downtime !== undefined && process.total_downtime !== null )?(process.total_downtime):(0))
      durationInMinutes -= ((process.total_schedule_downtime && process.total_schedule_downtime !== undefined && process.total_schedule_downtime !== null )?(process.total_schedule_downtime):(0))
      const durationInHours = (durationInMinutes)/60;
      return Math.floor(durationInHours * cycleTime);
    }
    return null;
  };

  //const resetProcessValues = (process: any) => {
  //  Object.assign(process, {
  //    date: moment(),
  //    shift: 'DAY',
  //    mc_no: null,
  //    part_id: null,
  //    rotor_size: null,
  //    batch_number: null,
  //    operator: null,
  //    operator_2: null,
  //    time_start: null,
  //    time_stop: null,
  //    shot: null,
  //    no_of_cavity: null,
  //    total_qty: null,
  //    ok_qty: null,
  //    rej_qty: null,
  //    verified_sign: null,
  //    remarks: null,
  //  });
  //};
//
  //useEffect(() => {
  //  moduleData.processes.forEach((process: any) => {
  //    process.plan_qty = calculatePlanQty(process);
  //  });
  //  setRender(prev => !prev);
  //}, [moduleData]);

  const handleTimeChange = (process: any, field: string, date: any) => {
    process[field] = date;
    process.plan_qty = calculatePlanQty(process);
    setRender((prev) => !prev);
  };

  const handlePartChange = (process: any, value: any) => {
    if (value === null) {
      process.part_id = null;
      process.partNumber = null;
      process.partName = null;
      //process.cycleTime = null;
    } else {
      process.part_id = value;
      process.partName = value.part_name;
      process.partNumber = value.part_number;
      //process.cycleTime = value.cycle_time;
    }
    //process.plan_qty = calculatePlanQty(process);
    setRender((prev) => !prev);
  };

  const handleSaveDowntime = (updatedProcess: any) => {
    if (currentProcessIndex !== null) {
      moduleData.processes[currentProcessIndex] = updatedProcess;
      setIsDowntimeModalOpen(false);
      setRender((prev) => !prev);
    }
  };

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              {/* <td style={{ minWidth: '150px' }}>
                {isView ? (
                  formatDate(process.date)
                ) : (
                  <DatePickerMui
                    label=""
                    value={moment(process.date)}
                    onChange={(date) => {
                      process.date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td> */}
              <td width={'100px'} style={{ fontWeight: 'bold' }}>
                {index + 1}
              </td>
              <td style={{ minWidth: '130px' }}>
                {isView ? (
                  process.shift
                ) : (
                  <CustomSelect
                    name="shift"
                    value={process.shift || ''}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="MORNING">MORNING</MenuItem>
                    <MenuItem value="DAY">DAY</MenuItem>
                    <MenuItem value="NIGHT">NIGHT</MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td style={{ minWidth: '200px' }}>
                {isView
                  ? process?.mc_no?.machine_no
                  : process.date &&
                    process.part_id && (
                      <AutocompleteMuiCustom
                        id="Machine"
                        label=""
                        option_name="machine_no"
                        arrayofObj={machineData}
                        value={process?.mc_no || ''}
                        onChange={(e, value) => {
                          if (value === null) value = null;
                          process.mc_no = value;
                          setRender((prev) => !prev);
                        }}
                      />
                    )}
              </td>
              <td style={{ width: '250px', minWidth: '250px' }}>
                {isView ? (
                  process?.part_id && process?.part_id?.part_name
                ) : (
                  <Grid container xs={12} direction="row" alignItems={'center'}>
                    <Grid item xs={10}>
                      <p
                        style={{
                          fontSize: '1.6rem',
                        }}
                      >
                        {typeof process?.part_id === 'object' &&
                        process?.part_id !== null
                          ? process?.part_id?.part_name
                          : process?.partName || ''}
                        {!process?.part_id && 'Pick Part Name'}
                      </p>
                    </Grid>

                    {process.part_id ? (
                      <Grid item xs={2}>
                        <RemoveIconButton
                          tooltipTitle="remove part"
                          onClick={() => handlePartChange(process, null)}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <AddIconButton
                          tooltipTitle="add part"
                          onClick={() => {
                            setIsOpenPopup(true);
                            setPartIdx(index);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </td>
              <td style={{ minWidth: '200px' }}>
                <OperationSelect
                  {...{ process, isView, setRender }}
                  partId={
                    typeof process.part_id === 'string'
                      ? process?.part_id
                      : process?.part_id?._id
                  }
                  documentType={documentType}
                />
              </td>
              <td style={{minWidth: '100px'}}>
                {process.cycle_time || process.cycleTime}
              </td>
              <td style={{ minWidth: '200px' }}>
                {process.mc_no && process.date && (
                  <Operator
                    onSubmit={handleSumbit}
                    field="operator"
                    machineId={process.mc_no?._id}
                    dataObj={{
                      date: moduleData.dailyProduction_date,
                      part_id: process.part_id,
                      operation_name: process.operation_name,
                    }}
                    {...{ isView, process }}
                  />
                )}
              </td>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  convToTime(process.time_start)
                ) : (
                  <TimePicker
                    // ampm={false}
                    value={moment(process.time_start)}
                    onChange={(date: any) => handleTimeChange(process, 'time_start', date)}
                  />
                )}
              </td>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  convToTime(process.time_stop)
                ) : (
                  <TimePicker
                    value={moment(process.time_stop)}
                    onChange={(date: any) => handleTimeChange(process, 'time_stop', date)}
                  />
                )}
              </td>
              <td style={{ minWidth: '200px' }}>
                <Grid container alignItems="center" spacing={1} display={'flex'} justifyContent={'center'}>
                  <Grid item>
                    {(process.total_downtime && process.total_downtime !== undefined && process.total_downtime !== null ) ? `${process.total_downtime} min` : 'No Downtime'}
                  </Grid>
                  <Grid item>
                    <AddIconButton
                      tooltipTitle='Edit Downtime Reasons'
                      onClick={() => {
                        setCurrentProcessIndex(index);
                        setIsDowntimeModalOpen(true);
                        setIsFreeze(true);
                      }}
                    />
                  </Grid>
                </Grid>
              </td>
              <td style={{ minWidth: '200px' }}>
                <Grid container alignItems="center" spacing={1} display={'flex'} justifyContent={'center'}>
                  <Grid item>
                    {(process.total_schedule_downtime && process.total_schedule_downtime !== undefined && process.total_schedule_downtime !== null ) ? `${process.total_schedule_downtime} min` : 'No Downtime'}
                  </Grid>
                  <Grid item>
                    <AddIconButton
                      tooltipTitle='Edit Schedule Downtime Reasons'
                      onClick={() => {
                        setCurrentProcessIndex(index);
                        setIsDowntimeModalOpen(true);
                        setIsFreeze(false);
                      }}
                    />
                  </Grid>
                </Grid>
              </td>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  process.plan_qty
                ) : (
                  <TableInput
                    isView={(documentType != 'pressShop3')}
                    name="plan_qty"
                    type="number"
                    value={process.plan_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  />
                )}
                {/*
                  <TableInput
                    isView={isView}
                    name="plan_qty"
                    type="number"
                    value={process.plan_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  />
                */}
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="actual_qty"
                  type="number"
                  value={process.actual_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{
    minWidth: '150px',
    background:
      process.plan_qty && process.actual_qty
        ? (process.actual_qty / process.plan_qty) * 100 < 80
          ? 'red'
          : 'inherit'
        : 'inherit',
    color:
      process.plan_qty && process.actual_qty
        ? (process.actual_qty / process.plan_qty) * 100 < 80
          ? 'white'
          : 'black'
        : 'inherit',
        }}>
                <TableInput
                  isView={true}
                  name="efficiency"
                  type="number"
                  value={
                    process.plan_qty && process.actual_qty
                      ? ((process.actual_qty / process.plan_qty) * 100).toFixed(2)
                      : 0
                  }
                  onChange={(e) => {
                    // handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '250px' }}>
                <TableInput
                  isView={isView}
                  name="lot_no"
                  type="text"
                  value={process.lot_no}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="rej_qty"
                  type="number"
                  value={process.rej_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  convToTime(process.bdtime_start)
                ) : (
                  <TimePicker
                    // ampm={false}
                    value={process.bdtime_start ? moment(process.bdtime_start) : null}
                    // value={moment(process.bdtime_start)}
                    onChange={(date: any) =>{ 
                      process.bdtime_start=date;
                      setRender((prev)=>!prev)
                      // handleTimeChange(process, 'bdtime_start', date)
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  convToTime(process.bdtime_stop)
                ) : (
                  <TimePicker
                  value={process.bdtime_stop ? moment(process.bdtime_stop) : null}                    onChange={(date: any) =>{ 
                      process.bdtime_stop=date;
                      setRender((prev)=>!prev)
                      // handleTimeChange(process, 'bdtime_start', date)
                    }}                  />
                )}
              </td>
              <td style={{ minWidth: '200px' }} colSpan={isView ? 2 : 1}>
              {isView ? (
        process?.bd_type
      ) : (
        <CustomSelect
          name="bd_type"
          value={process?.bd_type || ''}
          onChange={(e) => {
           process.bd_type=e.target.value
            setRender((prev: any) => !prev);
          }}
        >
          <MenuItem value="TOOL">TOOL</MenuItem>
          <MenuItem value="MACHINE">MACHINE</MenuItem>
         
        </CustomSelect>
      )}
              </td>
              <td style={{ minWidth: '200px' }} colSpan={isView ? 2 : 1}>
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <AddIconButton
                    tooltipTitle={'Add To 4M'}
                    disabled={true}
                    onClick={() => {
                      setModalIdx(index);
                      setIsOpen4mModel(true);
                    }}
                  />
                </td>
              )}
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
      <ModalCustom
        title="SUMMARY DATA"
        openModal={isOpen4mModel}
        closeModal={() => {
          setIsOpen4mModel(false);
        }}
      >
        <OperatorFor4mLogs
          onSubmit={handleSumbit}
          field="operator"
          machineId={moduleData?.processes?.[modalIdx]?.mc_no?._id}
          dataObj={{
            date: moduleData?.dailyProduction_date,
            part_id: moduleData?.processes?.[modalIdx]?.part_id,
            operation_name: moduleData?.processes?.[modalIdx]?.operation_name,
          }}
          process={moduleData?.processes?.[modalIdx]}
          {...{ isView, isOpen4mModel, setIsOpen4mModel }}
        />
      </ModalCustom>
      {currentProcessIndex !== null && (
        <DowntimeReasonModal
          process={moduleData.processes[currentProcessIndex]}
          isOpen={isDowntimeModalOpen}
          isFreeze={isFreeze}
          onClose={() => setIsDowntimeModalOpen(false)}
          onSave={handleSaveDowntime}
          documentType={documentType}
        />
      )}
    </>
  );
};

export default TBody;
